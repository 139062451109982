import * as types from "../types/index";
import axios from "axios";
import toast from "react-hot-toast";
import { refresh_profile, broker_logout } from "./authActions";
import { authHeader } from "../headers";

const url = "https://nasdmobile.nasdng.com/api/v1";
// const url = "http://146.190.205.147:3011/v1";

export const contact_support = (datas) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.SEND_MESSAGE_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.post(
			`${url}/users/contact-support?application=${getData}`,
			datas,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({ type: types.SEND_MESSAGE_SUCCESS, payload: data });
			toast.success(`Message has been sent.`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Something went wrong") {
			dispatch(broker_logout());
		}
		dispatch({ type: types.SEND_MESSAGE_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};

export const get_contact_messages = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.GET_MESSAGE_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.get(
			`${url}/users/contact-support?application=${getData}`,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({ type: types.GET_MESSAGE_SUCCESS, payload: data });
		}
	} catch (error) {
		console.log(error);
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Something went wrong") {
			dispatch(broker_logout());
		}
		dispatch({ type: types.GET_MESSAGE_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};
