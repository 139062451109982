import React from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';

import Meta from '../../components/Meta';
import {
  single_transaction_history,
  investor_wallet_balance,
} from '../../redux/actions/tradesAction';
import Preloader from '../../components/Preloader';
import TransactionHistoryTable from '../../components/Tables/TransactionHistoryTable';

const SingleTransactionHistory = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const tableRef = React.useRef();

  const { loading, investorHistory } = useSelector(
    (state) => state.singleInvestorTransactions
  );
  const { investorWallet } = useSelector(
    (state) => state.investorWalletBalance
  );

  React.useEffect(() => {
    if (id) {
      Promise.all([
        dispatch(single_transaction_history(id)),
        dispatch(investor_wallet_balance(id)),
      ]);
    }
  }, [dispatch, id]);

  return (
    <section className='section'>
      <Meta title='Investors transaction history' />
      <div className='container-fluid'>
        <div className='title-wrapper pt-30'>
          <div className='row align-items-center'>
            <div className='col-md-6'>
              <div className='title mb-30'>
                <h2>Investor Transaction History</h2>
              </div>
            </div>
            <div className='col-md-6'></div>
          </div>
        </div>

        <div>
          <p>
            Wallet Balance: <span>{investorWallet?.amount.toFixed(3)}</span>
          </p>
        </div>

        {loading ? (
          <Preloader />
        ) : (
          <Row>
            <Col lg={12}>
              <Card border='light' className='p-0 mb-4 mt-4'>
                <Card.Body className='pb-5'>
                  {investorHistory && investorHistory.length > 0 ? (
                    <TransactionHistoryTable
                      entries={investorHistory}
                      reference={tableRef}
                    />
                  ) : (
                    <h6>There are no transaction history for this user.</h6>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </div>
    </section>
  );
};

export default SingleTransactionHistory;
