import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { brokers_change_password } from "../redux/actions/authActions";
import PasswordInputField from "./PasswordInputField";
import ConfirmPasswordInputField from "./ConfirmPasswordInputField";

const PasswordAndConfirmPasswordValidation = () => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const [passwordError, setPasswordErr] = React.useState("");
    const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
    const [passwordInput, setPasswordInput]= React.useState({
        password: '',
        confirmPassword: ''
    });
    const [ oldPassword, setOldPassword ] = React.useState("");

    const handlePasswordChange =(evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;
        const NewPasswordInput = {...passwordInput,[passwordInputFieldName]:passwordInputValue}
        setPasswordInput(NewPasswordInput);
        
    };

    const handleValidation= (evnt)=>{
        const passwordInputValue = evnt.target.value.trim();
        const passwordInputFieldName = evnt.target.name;

        if(passwordInputFieldName==='password'){
            const uppercaseRegExp   = /(?=.*?[A-Z])/;
            const lowercaseRegExp   = /(?=.*?[a-z])/;
            const digitsRegExp      = /(?=.*?[0-9])/;
            const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
            const minLengthRegExp   = /.{8,}/;
            const passwordLength =      passwordInputValue.length;
            const uppercasePassword =   uppercaseRegExp.test(passwordInputValue);
            const lowercasePassword =   lowercaseRegExp.test(passwordInputValue);
            const digitsPassword =      digitsRegExp.test(passwordInputValue);
            const specialCharPassword = specialCharRegExp.test(passwordInputValue);
            const minLengthPassword =   minLengthRegExp.test(passwordInputValue);

            let errMsg ="";

            if(passwordLength===0){
                    errMsg="Password is empty";
            }else if(!uppercasePassword){
                    errMsg="At least one Uppercase";
            }else if(!lowercasePassword){
                    errMsg="At least one Lowercase";
            }else if(!digitsPassword){
                    errMsg="At least one digit";
            }else if(!specialCharPassword){
                    errMsg="At least one Special Characters";
            }else if(!minLengthPassword){
                    errMsg="At least minumum 8 characters";
            }else{
                errMsg="";
            }
            setPasswordErr(errMsg);
        }

        if(passwordInputFieldName === "confirmPassword" || (passwordInputFieldName==="password" && passwordInput.confirmPassword.length>0) ){
                
            if(passwordInput.confirmPassword!==passwordInput.password){
                setConfirmPasswordError("Password does not match");
            }else{
                setConfirmPasswordError("");
            }
            
        }
    };

    const submitBrokerMessage = () => {
        const datas = {
            oldPassword,
            newPassword: passwordInput.password,
            confirmPassword: passwordInput.confirmPassword,
            brokerId: detail.id
        };
        dispatch(brokers_change_password(datas));
        setOldPassword("");
    }

    return(
        <div className="row">
            <div className="input-style-1">
                <label>Old Password</label>
                <input 
                    type="password" 
                    placeholder="Old Password" 
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.target.value)}
                />
            </div>
            <div className="col-md-12">
                <PasswordInputField 
                    handlePasswordChange={handlePasswordChange} 
                    handleValidation={handleValidation} 
                    passwordValue={passwordInput.password} 
                    passwordError={passwordError}
                />
                <ConfirmPasswordInputField 
                    handlePasswordChange={handlePasswordChange} 
                    handleValidation={handleValidation} 
                    confirmPasswordValue={passwordInput.confirmPassword} 
                    confirmPasswordError={confirmPasswordError}
                />
            </div>
            <div
                className="
                    button-group
                    d-flex
                    justify-content-center
                    flex-wrap
                "
            >
                <button
                    className="main-btn primary-btn btn-hover w-100 text-center"
                    style={{ backgroundColor: "#252063", color: "#ffffff"}}
                    onClick={submitBrokerMessage}
                >
                    Send
                </button>
            </div>
        </div>
    )
}
export default PasswordAndConfirmPasswordValidation;