export const GET_INDIV_INVESTOR_REQUEST = "GET_INDIV_INVESTOR_REQUEST";
export const GET_INDIV_INVESTOR_SUCCESS = "GET_INDIV_INVESTOR_SUCCESS";
export const GET_INDIV_INVESTOR_FAIL = "GET_INDIV_INVESTOR_FAIL";
export const GET_INDIV_INVESTOR_RESET = "GET_INDIV_INVESTOR_RESET";

export const SINGLE_INDIV_INVESTOR_REQUEST = "SINGLE_INDIV_INVESTOR_REQUEST";
export const SINGLE_INDIV_INVESTOR_SUCCESS = "SINGLE_INDIV_INVESTOR_SUCCESS";
export const SINGLE_INDIV_INVESTOR_FAIL = "SINGLE_INDIV_INVESTOR_FAIL";
export const SINGLE_INDIV_INVESTOR_RESET = "SINGLE_INDIV_INVESTOR_RESET";

export const CONFIRM_INVESTOR_ACCOUNT_REQUEST =
  "CONFIRM_INVESTOR_ACCOUNT_REQUEST";
export const CONFIRM_INVESTOR_ACCOUNT_SUCCESS =
  "CONFIRM_INVESTOR_ACCOUNT_SUCCESS";
export const CONFIRM_INVESTOR_ACCOUNT_FAIL = "CONFIRM_INVESTOR_ACCOUNT_FAIL";
export const CONFIRM_INVESTOR_ACCOUNT_RESET = "CONFIRM_INVESTOR_ACCOUNT_RESET";

export const UPDATE_INVESTOR_STATUS_REQUEST = "UPDATE_INVESTOR_STATUS_REQUEST";
export const UPDATE_INVESTOR_STATUS_SUCCESS = "UPDATE_INVESTOR_STATUS_SUCCESS";
export const UPDATE_INVESTOR_STATUS_FAIL = "UPDATE_INVESTOR_STATUS_FAIL";
export const UPDATE_INVESTOR_STATUS_RESET = "UPDATE_INVESTOR_STATUS_RESET";

export const DECLINE_INVESTOR_REQUEST = "DECLINE_INVESTOR_REQUEST";
export const DECLINE_INVESTOR_SUCCESS = "DECLINE_INVESTOR_SUCCESS";
export const DECLINE_INVESTOR_FAIL = "DECLINE_INVESTOR_FAIL";
export const DECLINE_INVESTOR_RESET = "DECLINE_INVESTOR_RESET";

export const GET_ALL_DECLINED_INVESTOR_REQUEST =
  "GET_ALL_DECLINED_INVESTOR_REQUEST";
export const GET_ALL_DECLINED_INVESTOR_SUCCESS =
  "GET_ALL_DECLINED_INVESTOR_SUCCESS";
export const GET_ALL_DECLINED_INVESTOR_FAIL = "GET_ALL_DECLINED_INVESTOR_FAIL";
export const GET_ALL_DECLINED_INVESTOR_RESET =
  "GET_ALL_DECLINED_INVESTOR_RESET";

export const GET_SINGLE_DECLINED_INVESTOR_REQUEST =
  "GET_SINGLE_DECLINED_INVESTOR_REQUEST";
export const GET_SINGLE_DECLINED_INVESTOR_SUCCESS =
  "GET_SINGLE_DECLINED_INVESTOR_SUCCESS";
export const GET_SINGLE_DECLINED_INVESTOR_FAIL =
  "GET_SINGLE_DECLINED_INVESTOR_FAIL";
export const GET_SINGLE_DECLINED_INVESTOR_RESET =
  "GET_SINGLE_DECLINED_INVESTOR_RESET";

export const GET_ALL_TRADE_ORDERS_REQUEST = "GET_ALL_TRADE_ORDERS_REQUEST";
export const GET_ALL_TRADE_ORDERS_SUCCESS = "GET_ALL_TRADE_ORDERS_SUCCESS";
export const GET_ALL_TRADE_ORDERS_FAIL = "GET_ALL_TRADE_ORDERS_FAIL";
export const GET_ALL_TRADE_ORDERS_RESET = "GET_ALL_TRADE_ORDERS_RESET";

export const GET_SINGLE_TRADE_ORDER_REQUEST = "GET_SINGLE_TRADE_ORDER_REQUEST";
export const GET_SINGLE_TRADE_ORDER_SUCCESS =
  "GET_SINGLE_TRADE_ORDER_REQUEST_SUCCESS";
export const GET_SINGLE_TRADE_ORDER_FAIL =
  "GET_SINGLE_TRADE_ORDER_REQUEST_FAIL";
export const GET_SINGLE_TRADE_ORDER_RESET =
  "GET_SINGLE_TRADE_ORDER_REQUEST_RESET";

export const RELEASE_ORDER_REQUEST = "RELEASE_ORDER_REQUEST";
export const RELEASE_ORDER_SUCCESS = "RELEASE_ORDER_SUCCESS";
export const RELEASE_ORDER_FAIL = "RELEASE_ORDER_FAIL";
export const RELEASE_ORDER_RESET = "RELEASE_ORDER_RESET";
