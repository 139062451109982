import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import configureStore from './redux/store';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
// import DSCForm from './components/DSCForm';

// imports
import './assets/css/bootstrap.min.css'
import './assets/css/LineIcons.css'
import './assets/css/vanilla-dataTables.min.css'
import './assets/css/main.css';

const store = configureStore();

const persistor = persistStore(store)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter>
          <App />
        </HashRouter>
        </PersistGate>
    </Provider>
  </>
);

