import React from "react";
import Meta from "../../components/Meta";
import { useDispatch, useSelector } from "react-redux";
import {
  get_all_pouchii_locked_transactions,
  get_all_pouchii_unlocked_transactions,
  get_all_pouchii_released_transactions,
} from "../../redux/actions/settlementAction";
import { Row, Col, Card, Tab, Tabs } from "@themesberg/react-bootstrap";
import Preloader from "../../components/Preloader";
import LockedTransactionsTable from "../../components/Tables/LockedTransactions";
import ReleasedTransactionsTable from "../../components/Tables/ReleasedTransactions";

const PouchiiTransactions = () => {
  const allLockedTransactions = useSelector((state) => state.pouchiiLock);
  const { loading, pouchiiTransactions } = allLockedTransactions;
  const allUnLockedTransactions = useSelector((state) => state.pouchiiUnLock);
  const { pouchiiLockedTransactions } = allUnLockedTransactions;
  const allReleaseTransactions = useSelector((state) => state.pouchiiRelease);
  const { pouchiiReleasedTransactions } = allReleaseTransactions;

  const dispatch = useDispatch();

  React.useEffect(() => {
    Promise.all([
      dispatch(get_all_pouchii_locked_transactions({ filter: "lock" })),
      dispatch(get_all_pouchii_unlocked_transactions({ filter: "unlock" })),
      dispatch(get_all_pouchii_released_transactions({ filter: "release" })),
    ]);
  }, []);

  return (
    <>
      <Meta title="All Trade Requests For A Broker" />
      <section className="section">
        <div className="container-fluid">
          <div className="title-wrapper pt-30">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title mb-30">
                  <h2>All Pouchii Transactions</h2>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>

          <Tabs defaultActiveKey="locked" className="mb-3">
            <Tab eventKey="locked" title="Locked">
              {loading ? (
                <Preloader />
              ) : (
                <Row>
                  <Col lg={12}>
                    <Card border="light" className="p-0 mb-4">
                      <Card.Body className="pb-5">
                        {pouchiiTransactions &&
                          pouchiiTransactions.transactions.length > 0 && (
                            <LockedTransactionsTable
                              entries={pouchiiTransactions.transactions}
                              type="lock"
                            />
                          )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="unlocked" title="Unlocked">
              {loading ? (
                <Preloader />
              ) : (
                <Row>
                  <Col lg={12}>
                    <Card border="light" className="p-0 mb-4">
                      <Card.Body className="pb-5">
                        {pouchiiLockedTransactions &&
                          pouchiiLockedTransactions.transactions.length > 0 && (
                            <LockedTransactionsTable
                              entries={pouchiiLockedTransactions.transactions}
                              type="unlock"
                            />
                          )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Tab>
            <Tab eventKey="released" title="Released">
              {loading ? (
                <Preloader />
              ) : (
                <Row>
                  <Col lg={12}>
                    <Card border="light" className="p-0 mb-4">
                      <Card.Body className="pb-5">
                        {pouchiiReleasedTransactions &&
                          pouchiiReleasedTransactions.transactions.length >
                            0 && (
                            <ReleasedTransactionsTable
                              entries={pouchiiReleasedTransactions.transactions}
                              type="release"
                            />
                          )}
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Tab>
          </Tabs>
        </div>
      </section>
    </>
  );
};

export default PouchiiTransactions;
