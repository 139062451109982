import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { verifyOtp } from '../redux/actions/authActions'
import { useSelector, useDispatch } from 'react-redux';
import toast from 'react-hot-toast'
import Meta from '../components/Meta';


const OTP = () => {
    const [ password, setPassword ] = React.useState("");
    const [ confirmPassword, setConfirmPassword ] = React.useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [ searchParams ] = useSearchParams();

    const token = searchParams.get('token');
    const userId = searchParams.get('id');

    const otp = useSelector((state) => state.otp);
	const { loading } = otp;

    const submitHandler = (e) => {
        e.preventDefault();
        let data = {
            password,
            token,
            userId
        }
        if ( password === confirmPassword ) {
            dispatch(verifyOtp(data));
            setPassword("");

            navigate('/')
        } else {
            toast.error( "Password does not match.", { position: "top-right " })
        };
    };

    return (
        <>
            <Meta title="Brokers login" />
            <section className="section">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="signin-wrapper mt-5">
                                <div className="form-wrapper">
                                    <div className="navbar-logo text-center mb-5">
                                        <img src="https://nasdng.com/wp-content/uploads/2018/10/NASD-LOGO-PLC.png" alt="logo" />
                                    </div>
                                    <h4 className="mb-5">Enter your new password to continue.</h4>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-style-1">
                                                    <label>Password</label>
                                                    <input 
                                                        type="password" 
                                                        placeholder="New Password" 
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                    <label className='mt-3'>Confirm Password</label>
                                                    <input 
                                                        type="password" 
                                                        placeholder="Confirm Password" 
                                                        value={confirmPassword}
                                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="col-12">
                                                <div
                                                    className="
                                                        button-group
                                                        d-flex
                                                        justify-content-center
                                                        flex-wrap
                                                    "
                                                >
                                                    <button
                                                        className="main-btn primary-btn btn-hover w-100 text-center"
                                                        style={{ backgroundColor: "#252063", color: "#ffffff"}}
                                                        onClick={submitHandler}
                                                        disabled={
                                                            password && !loading
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        Submit{" "}
                                                        {loading && (
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default OTP