export const ALL_INVESTOR_TRADE_REQUEST = "ALL_INVESTOR_TRADE_REQUEST";
export const ALL_INVESTOR_TRADE_SUCCESS = "ALL_INVESTOR_TRADE_SUCCESS";
export const ALL_INVESTOR_TRADE_FAIL = "ALL_INVESTOR_TRADE_FAIL";
export const ALL_INVESTOR_TRADE_RESET = "ALL_INVESTOR_TRADE_RESET";

export const ALL_INVESTOR_SELL_TRADE_REQUEST =
  "ALL_INVESTOR_SELL_TRADE_REQUEST";
export const ALL_INVESTOR_SELL_TRADE_SUCCESS =
  "ALL_INVESTOR_SELL_TRADE_SUCCESS";
export const ALL_INVESTOR_SELL_TRADE_FAIL = "ALL_INVESTOR_SELL_TRADE_FAIL";
export const ALL_INVESTOR_SELL_TRADE_RESET = "ALL_INVESTOR_SELL_TRADE_RESET";

export const ADMIN_INVESTOR_TRADE_REQUEST = "ADMIN_INVESTOR_TRADE_REQUEST";
export const ADMIN_INVESTOR_TRADE_SUCCESS = "ADMIN_INVESTOR_TRADE_SUCCESS";
export const ADMIN_INVESTOR_TRADE_FAIL = "ADMIN_INVESTOR_TRADE_FAIL";
export const ADMIN_INVESTOR_TRADE_RESET = "ADMIN_INVESTOR_TRADE_RESET";

export const INVESTOR_TRANSACTION_HISTORY_REQUEST =
  "INVESTOR_TRANSACTION_HISTORY_REQUEST";
export const INVESTOR_TRANSACTION_HISTORY_SUCCESS =
  "INVESTOR_TRANSACTION_HISTORY_SUCCESS";
export const INVESTOR_TRANSACTION_HISTORY_FAIL =
  "INVESTOR_TRANSACTION_HISTORY_FAIL";
export const INVESTOR_TRANSACTION_HISTORY_RESET =
  "INVESTOR_TRANSACTION_HISTORY_RESET";

export const SINGLE_INVESTOR_TRANSACTION_HISTORY_REQUEST =
  "SINGLE_INVESTOR_TRANSACTION_HISTORY_REQUEST";
export const SINGLE_INVESTOR_TRANSACTION_HISTORY_SUCCESS =
  "SINGLE_INVESTOR_TRANSACTION_HISTORY_SUCCESS";
export const SINGLE_INVESTOR_TRANSACTION_HISTORY_FAIL =
  "SINGLE_INVESTOR_TRANSACTION_HISTORY_FAIL";
export const SINGLE_INVESTOR_TRANSACTION_HISTORY_RESET =
  "SINGLE_INVESTOR_TRANSACTION_HISTORY_RESET";

export const SINGLE_INVESTOR_WALLET_REQUEST = "SINGLE_INVESTOR_WALLET_REQUEST";
export const SINGLE_INVESTOR_WALLET_SUCCESS = "SINGLE_INVESTOR_WALLET_SUCCESS";
export const SINGLE_INVESTOR_WALLET_FAIL = "SINGLE_INVESTOR_WALLET_FAIL";
export const SINGLE_INVESTOR_WALLET_RESET = "SINGLE_INVESTOR_WALLET_RESET";

export const RELEASE_FUNDS_REQUEST = "RELEASE_FUNDS_REQUEST";
export const RELEASE_FUNDS_SUCCESS = "RELEASE_FUNDS_SUCCESS";
export const RELEASE_FUNDS_FAIL = "RELEASE_FUNDS_FAIL";
export const RELEASE_FUNDS_RESET = "RELEASE_FUNDS_RESET";
