import React from 'react';
import  { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card, Tab, Tabs } from '@themesberg/react-bootstrap';
import BuyTable from '../../components/Tables/BuyTable';
import SellTable from '../../components/Tables/SellTable';
import Meta from '../../components/Meta';
import { 
    get_single_broker_buy_trades,
    get_single_broker_sell_trades
} from '../../redux/actions/tradesAction';
import Preloader from '../../components/Preloader';

const AllTradeRequests = () => {
    const dispatch = useDispatch();

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const trades = useSelector((state) => state.trades);
    const { loading, investorTrades } = trades;

    const saleOrder = useSelector((state) => state.saleOrder);
    const { loading: saleLoading, saleTrades } = saleOrder;

    React.useEffect(() => {
        const code = detail && detail.brokerCode; 

        dispatch(get_single_broker_buy_trades(code));
        dispatch(get_single_broker_sell_trades(code))
    }, []);

    return (
        <>
            <Meta title='All Trade Requests For A Broker' />
            <section className="section">
                <div className="container-fluid">
                    <div className="title-wrapper pt-30">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="title mb-30">
                                    <h2>All Trade Requests</h2>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>

                    <Tabs
                        defaultActiveKey="buy_orders"
                        className="mb-3"
                    >
                        <Tab eventKey="buy_orders" title="Buy Orders">
                            {
                                loading ?
                                    (
                                        <Preloader />
                                    )   : (
                                        <Row>
                                            <Col lg={12}>
                                                <Card border='light' className='p-0 mb-4'>
                                                    <Card.Body className='pb-5'>
                                                        {
                                                            investorTrades && 
                                                                investorTrades.length > 0 && (
                                                                    <BuyTable entries={investorTrades} />
                                                                )
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    ) 
                                }
                        </Tab>

                        <Tab eventKey="sell_orders" title="Sell Orders">
                            {
                                saleLoading ?
                                    (
                                        <Preloader />
                                    )   : (
                                        <Row>
                                            <Col lg={12}>
                                                <Card border='light' className='p-0 mb-4'>
                                                    <Card.Body className='pb-5'>
                                                        {
                                                            saleTrades && 
                                                                saleTrades.length > 0 && (
                                                                    <SellTable entries={saleTrades} />
                                                                )
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    )
                                }
                        </Tab>
                    </Tabs>
                </div>
            </section>
        </>
    )
}

export default AllTradeRequests