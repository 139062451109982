import React from "react";
import DataTable from "../../components/Tables/AllInvestorsTable";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Card } from "@themesberg/react-bootstrap";
import Meta from "../../components/Meta";
import { get_all_individual_investors } from "../../redux/actions/investorsActions";
import Preloader from "../../components/Preloader";

const AllInvestors = () => {
  const dispatch = useDispatch();
  const tableRef = React.useRef();

  const auth = useSelector((state) => state.auth);
  const { detail } = auth;

  const individual = useSelector((state) => state.individual);
  const { loading, indivInvestor } = individual;

  React.useEffect(() => {
    dispatch(get_all_individual_investors());
  }, [dispatch]);

  return (
    <section className="section">
      <Meta title="New added investors" />
      <div className="container-fluid">
        <div className="title-wrapper pt-30">
          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="title mb-30">
                <h2>All Investors</h2>
              </div>
            </div>
            <div className="col-md-6"></div>
          </div>
        </div>
      </div>

      {loading ? (
        <Preloader />
      ) : (
        <Row>
          <Col lg={12}>
            <Card border="light" className="p-0 mb-4">
              <Card.Body className="pb-5">
                {indivInvestor && indivInvestor.length > 0 ? (
                  <DataTable entries={indivInvestor} reference={tableRef} />
                ) : (
                  <h6>There are no registered investors.</h6>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </section>
  );
};

export default AllInvestors;
