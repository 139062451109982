import React from 'react';
import { Row, Col, Card, ButtonGroup, Button } from '@themesberg/react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import * as htmlToImage from 'html-to-image';
import Meta from '../../components/Meta';
import { get_single_broker } from '../../redux/actions/authActions';
import Preloader from '../../components/Preloader'
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

const SingleBroker = () => {
    
    const params = useParams();
    const dispatch = useDispatch();
    const getBroker = useSelector((state) => state.getBroker)
    const { loading, singleBroker } = getBroker;
    const brokerName = singleBroker ? `${singleBroker.name}` : "Brokers Profile";
    
    const printDocument = () => {
        htmlToImage.toPng(document.getElementById('divToPrint'), { quality: 1.00 })
        .then(function (dataUrl) {
            let link = document.createElement('a');
            link.download = 'my-image-name.jpeg';
            const pdf = new jsPDF();
            const imgProps= pdf.getImageProperties(dataUrl);
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
            pdf.addImage(dataUrl, 'PNG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`${brokerName}.pdf`); 
        });
    };

    React.useEffect(() => {
        if(params.id){
            dispatch(get_single_broker(params.id));
        }
    }, [params.id])

    return (
        <>
            {
                loading ? 
                    (<Preloader /> ) 
                    : (
                        <section className="section">
                        <Meta title="Single users trade request." />
                        <div className="container-fluid">
                            <div className="title-wrapper pt-30">
                                <div className="d-flex align-items-center justify-content-between">
                                    <div className="">
                                        <div className="title mb-30">
                                            <h2>Broker Information</h2>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            paddingBottom: "25px"
                                        }}
                                    >
                                        <ButtonGroup>
                                            <Button
                                                variant="outline-secondary" 
                                                size="sm" 
                                                className="px-3"
                                                onClick={() => printDocument()}
                                            >
                                                Export PDF
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>

                            {/* Single Broker Informations */}
                            
                            {
                                singleBroker && 
                                    singleBroker.name && (
                                        <div>
                                            <Row>
                                                <Col lg={12}>
                                                    <Card border='light' className='p-0 mb-4'>
                                                        <Card.Body className='pb-5'>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="input-style-1">
                                                                        <label>Name</label>
                                                                        <input 
                                                                            type="text" 
                                                                            value={singleBroker.name} 
                                                                            disabled 
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="input-style-1">
                                                                        <label>Email</label>
                                                                        <input 
                                                                            type="email" 
                                                                            value={singleBroker.email} 
                                                                            disabled 
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="input-style-1">
                                                                        <label>Phone</label>
                                                                        <input 
                                                                            type="text" 
                                                                            value={singleBroker.phone} 
                                                                            disabled 
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="input-style-1">
                                                                        <label>Policy</label>
                                                                        <input 
                                                                            type="text" 
                                                                            value={singleBroker.policy} 
                                                                            disabled 
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <div className="input-style-1">
                                                                        <label>Account Number</label>
                                                                        <input 
                                                                            type="text" 
                                                                            value={singleBroker.accountNumber ? singleBroker.accountNumber : "Not Available"} 
                                                                            disabled 
                                                                        />
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className="input-style-1">
                                                                        <label>Bank Name</label>
                                                                        <input 
                                                                            type="text" 
                                                                            value={singleBroker.bankName ? singleBroker.bankName : "Not Available"} 
                                                                            disabled 
                                                                        />
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                            {/* <DSCForm printId={`divToPrint`} /> */}
                                        </div>
                                        
                                    )
                            }
                        </div>
                    </section>    
                )
            }       
        </>
    )
}

export default SingleBroker;