import * as types from "../types/index";

export const settlementReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.SETTLEMENT_REQUEST:
      return { ...state, loading: true };
    case types.SETTLEMENT_SUCCESS:
      return { loading: false, settlements: action.payload };
    case types.SETTLEMENT_FAIL:
      return { loading: false, error: action.payload };
    case types.SETTLEMENT_RESET:
      return {};
    default:
      return state;
  }
};

export const getBanksReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.GET_ALL_BANKS_REQUEST:
      return { ...state, loading: true };
    case types.GET_ALL_BANKS_SUCCESS:
      return { loading: false, getBanks: action.payload };
    case types.GET_ALL_BANKS_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_ALL_BANKS_RESET:
      return {};
    default:
      return state;
  }
};

export const getPouchiiTransactionReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.POUCHII_TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case types.POUCHII_TRANSACTION_SUCCESS:
      return { loading: false, pouchiiTransactions: action.payload };
    case types.POUCHII_TRANSACTION_RESET:
      return { loading: false, error: action.payload };
    case types.POUCHII_TRANSACTION_FAIL:
      return {};
    default:
      return state;
  }
};
export const getPouchiiUnlockedTransactionReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.POUCHII_UNLOCK_TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case types.POUCHII_UNLOCK_TRANSACTION_SUCCESS:
      return { loading: false, pouchiiLockedTransactions: action.payload };
    case types.POUCHII_UNLOCK_TRANSACTION_RESET:
      return { loading: false, error: action.payload };
    case types.POUCHII_UNLOCK_TRANSACTION_FAIL:
      return {};
    default:
      return state;
  }
};
export const getPouchiiReleasedTransactionReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.POUCHII_RELEASE_TRANSACTION_REQUEST:
      return { ...state, loading: true };
    case types.POUCHII_RELEASE_TRANSACTION_SUCCESS:
      return { loading: false, pouchiiReleasedTransactions: action.payload };
    case types.POUCHII_RELEASE_TRANSACTION_RESET:
      return { loading: false, error: action.payload };
    case types.POUCHII_RELEASE_TRANSACTION_FAIL:
      return {};
    default:
      return state;
  }
};
