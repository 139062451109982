import * as types from "../types/index"

export const authReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.AUTH_REQUEST:
            return { ...state, loading: true };
        case types.AUTH_SUCCESS:
            return { loading: false, detail: action.payload };
        case types.AUTH_FAIL:
            return { loading: false, error: action.payload };
        case types.AUTH_LOGOUT:
            return {};
        default:
            return state;
    }
}

export const getSingleBrokersReducer = (state = { loading: false }, action) => {
	switch (action.type) {
		case types.GET_SINGLE_BROKER_REQUEST:
			return { ...state, loading: true };
		case types.GET_SINGLE_BROKER_SUCCESS:
			return { loading: false, singleBroker: action.payload };
		case types.GET_SINGLE_BROKER_FAIL:
			return { loading: false, error: action.payload, profile: {} };
		case types.GET_SINGLE_BROKER_RESET:
			return {};
		default:
			return state;
	}
};

export const getBrokersReducer = (state = { brokers: {} }, action) => {
	switch (action.type) {
		case types.GET_ALL_BROKER_REQUEST:
			return { ...state, loading: true, brokers: {} };
		case types.GET_ALL_BROKER_SUCCESS:
			return { loading: false, brokers: action.payload };
		case types.GET_ALL_BROKER_FAIL:
			return { loading: false, error: action.payload, profile: {} };
		case types.GET_ALL_BROKER_RESET:
			return {};
		default:
			return state;
	}
};

export const getOtpReducer = (state = { loading: false }, action) => {
	switch (action.type) {
		case types.GET_OTP_REQUEST:
			return { ...state, loading: true };
		case types.GET_OTP_SUCCESS:
			return { loading: false, brokerOtp: action.payload };
		case types.GET_OTP_FAIL:
			return { loading: false, error: action.payload };
		case types.GET_OTP_RESET:
			return {};
		default:
			return state;
	}
};

export const getRecoveryPasswordReducer = (state ={ loading: false }, action) => {
	switch (action.type) {
		case types.POST_RECOVERY_PASSWORD_REQUEST:
			return { ...state, loading: true};
		case types.POST_RECOVERY_PASSWORD_SUCCESS:
			return { loading: false, recovery: action.payload };
		case types.POST_RECOVERY_PASSWORD_FAIL:
			return { loading: false, error: action.payload };
		case types.POST_RECOVERY_PASSWORD_RESET:
			return {};
		default:
			return state;
	}
};

export const brokerProfileReducer = (state = { profile: {} }, action) => {
	switch (action.type) {
		case types.GET_BROKER_PROFILE_REQUEST:
			return { ...state, loading: true, profile: {} };
		case types.GET_BROKER_PROFILE_SUCCESS:
			return { loading: false, profile: action.payload };
		case types.GET_BROKER_PROFILE_FAIL:
			return { loading: false, error: action.payload, profile: {} };
		case types.GET_BROKER_PROFILE_RESET:
			return {};
		default:
			return state;
	}
};


export const createBrokerReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.CREATE_BROKER_REQUEST:
            return { ...state, loading: true };
        case types.CREATE_BROKER_SUCCESS:
            return { loading: false, detail: action.payload };
        case types.CREATE_BROKER_FAIL:
            return { loading: false, error: action.payload };
        case types.CREATE_BROKER_RESET:
            return {};
        default:
            return state;
    }
}

export const changePasswordReducer = (state = { loading: false}, action) => {
	switch (action.type) {
		case types.CHANGE_BROKERS_PASSWORD_REQUEST:
			return { ...state, loading: true};
		case types.CHANGE_BROKERS_PASSWORD_SUCCESS:
			return { loading: false, password: action.payload };
		case types.CHANGE_BROKERS_PASSWORD_FAIL:
			return { loading: false, error: action.payload };
		case types.CHANGE_BROKERS_PASSWORD_RESET:
			return {};
		default:
			return state;
	}
};

export const brokerPasswordREcoveryReducer = (state = { loading: false }, action) => {
    switch (action.type) {
        case types.BROKERS_PASSWORD_RECOVERY_REQUEST:
            return { ...state, loading: true };
        case types.BROKERS_PASSWORD_RECOVERY_SUCCESS:
            return { loading: false, verifiedAccount: action.payload };
        case types.BROKERS_PASSWORD_RECOVERY_FAIL:
            return { loading: false, error: action.payload };
        case types.BROKERS_PASSWORD_RECOVERY_RESET:
            return {};
        default:
            return state;
    }
}

