import React from 'react'
import { Button } from '@themesberg/react-bootstrap'

const ExportCSV = ({ children, onExport, ...props }) => {
    return (
        
        <Button
            { ...props }
            onClick={() => { onExport() }}
        >
            {children}
        </Button>
    )
}

export default ExportCSV