import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { broker_login } from "../redux/actions/authActions";
import { useSelector, useDispatch } from "react-redux";
import Meta from "../components/Meta";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const viewHandler = () => {
    let pass = document.getElementById("password");
    let view = document.getElementById("view");
    const type = pass.getAttribute("type") === "password" ? "text" : "password";
    pass.setAttribute("type", type);

    view.classList.toggle("fa-eye-slash");
  };

  const application = "NASD";

  window.localStorage.setItem("devoper", "NASD");

  const auth = useSelector((state) => state.auth);
  const { loading, detail } = auth;

  const submitHandler = (e) => {
    e.preventDefault();
    let data = {
      email,
      password,
      application,
    };
    dispatch(broker_login(data));
  };

  React.useEffect(() => {
    if (detail) {
      navigate("/dashboard");
    }
  }, [detail, navigate]);

  return (
    <>
      <Meta title="Brokers login" />
      <section className="section">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="signinc-wrapper mt-5">
                <div className="form-wrapper">
                  <div className="navbar-logo text-center mb-5">
                    <img
                      src="https://nasdng.com/wp-content/uploads/2018/10/NASD-LOGO-PLC.png"
                      alt="logo"
                    />
                  </div>
                  <h2 className="mb-15">Sign In To Your Account...</h2>
                  <form action="#">
                    <div className="row">
                      <div className="col-12">
                        <div className="input-style-1">
                          <label>Email</label>
                          <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="input-style-1">
                          <label>Password</label>
                          <input
                            id="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>
                      <span
                        id="view"
                        onClick={() => viewHandler()}
                        style={{
                          cursor: "pointer",
                          marginTop: -20,
                          marginBottom: 20,
                        }}
                      >
                        Click to show password.
                      </span>
                      <div className="col-xxl-6 col-lg-12 col-md-6">
                        <div className="form-check checkbox-style mb-30">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="checkbox-remember"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="checkbox-remember"
                          >
                            Remember me next time
                          </label>
                        </div>
                      </div>
                      <div className="col-xxl-6 col-lg-12 col-md-6">
                        <div
                          className="
                                                    text-start text-md-end text-lg-start text-xxl-end
                                                    mb-30
                                                "
                        >
                          <Link
                            to="/forgot-password"
                            className="hover-underline"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>
                      <div className="col-12">
                        <div
                          className="
                                                        button-group
                                                        d-flex
                                                        justify-content-center
                                                        flex-wrap
                                                    "
                        >
                          <button
                            className="main-btn primary-btn btn-hover w-100 text-center"
                            style={{
                              backgroundColor: "#252063",
                              color: "#ffffff",
                            }}
                            onClick={submitHandler}
                            disabled={
                              email && password && !loading ? false : true
                            }
                          >
                            Login{" "}
                            {loading && (
                              <i className="fas fa-spinner fa-spin"></i>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
