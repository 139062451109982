import * as types from "../types/index";
import { authHeader } from "../headers";
import axios from "axios";
import toast from "react-hot-toast";

// const url = 'http://localhost:3001/v1'
const url = "https://nasdmobile.nasdng.com/api/v1";
// const url = "http://146.190.205.147:3011/v1";

export const broker_login = (datas) => async (dispatch) => {
	try {
		dispatch({ type: types.AUTH_REQUEST });

		const { data } = await axios.post(`${url}/auth/login`, datas);

		if (data) {
			dispatch({ type: types.AUTH_SUCCESS, payload: data });
			toast.success(`Welcome ${data.name}`, {
				position: "top-right",
			});
		}
	} catch (error) {
		if (error.message === "Network Error") {
			dispatch({ type: types.AUTH_FAIL, payload: error.message });
			toast.error(error.message, { position: "top-right " });
		} else {
			const message = error.response
				? error.response.data.error
				: "Something went wrong";
			dispatch({ type: types.AUTH_FAIL, payload: message });
			toast.error(message, { position: "top-right " });
		}
	}
};

export const refresh_profile = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.GET_BROKER_PROFILE_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.get(
			`${url}/users/${id}?application=${getData}`,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({
				type: types.GET_BROKER_PROFILE_SUCCESS,
				payload: data,
			});

			let passedData = {
				...data,
				token: detail.token,
			};
			dispatch({
				type: types.AUTH_SUCCESS,
				payload: passedData,
			});
		}
	} catch (error) {
		console.log(error);
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Not Authorized") {
			dispatch(broker_logout());
		}
		dispatch({ type: types.GET_BROKER_PROFILE_FAIL, payload: message });
		toast.error(message, { position: "top-right" });
	}
};

export const create_brokers_account = (details) => async (dispatch) => {
	try {
		dispatch({ type: types.CREATE_BROKER_REQUEST });

		const { data } = await axios.post(
			`${url}/auth/register-broker?application=devoper`,
			details
		);

		if (data) {
			dispatch({ type: types.CREATE_BROKER_SUCCESS, payload: data });
			toast.success(`Brokers account created!`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";

		dispatch({ type: types.CREATE_BROKER_FAIL, payload: message });
		toast.error("Email account already exist.", { position: "top-right " });
	}
};

export const get_single_broker = (id) => async (dispatch, getState) => {
	try {
		dispatch({ type: types.GET_SINGLE_BROKER_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.get(
			`${url}/users/${id}?application=${getData}`,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({ type: types.GET_SINGLE_BROKER_SUCCESS, payload: data });
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";

		dispatch({ type: types.GET_SINGLE_BROKER_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};

export const get_all_brokers_account = () => async (dispatch, getState) => {
	try {
		dispatch({ type: types.GET_ALL_BROKER_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.get(`${url}/users?application=${getData}`, {
			headers: authHeader(detail.token),
		});

		if (data) {
			dispatch({ type: types.GET_ALL_BROKER_SUCCESS, payload: data });
		}
	} catch (error) {
		console.log(error);
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";

		dispatch({ type: types.GET_ALL_BROKER_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};

export const brokers_change_password = (details) => async (dispatch) => {
	try {
		dispatch({ type: types.CHANGE_BROKERS_PASSWORD_REQUEST });

		const { data } = await axios.patch(
			`${url}/users/update/password/?application=devoper`,
			details
		);

		if (data) {
			dispatch({ type: types.CHANGE_BROKERS_PASSWORD_SUCCESS, payload: data });
			toast.success(`Password has been updated!`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";

		dispatch({ type: types.CHANGE_BROKERS_PASSWORD_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};

export const broker_password_recovery = (datas) => async (dispatch) => {
	console.log(datas);

	try {
		dispatch({ type: types.BROKERS_PASSWORD_RECOVERY_REQUEST });

		const { data } = await axios.post(`${url}/auth/password-recovery`, datas);

		if (data) {
			dispatch({
				type: types.BROKERS_PASSWORD_RECOVERY_SUCCESS,
				payload: data,
			});
			toast.success(`Kindly check your registered email to continue.`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Server Error") {
			dispatch();
		}
		dispatch({ type: types.BROKERS_PASSWORD_RECOVERY_FAIL, payload: message });
		toast.error("You will receive an email if your account is registered.", {
			position: "top-right ",
		});
	}
};

export const verifyOtp = (datas) => async (dispatch) => {
	// const url = "http://localhost:3001/v1";
	try {
		dispatch({ type: types.GET_OTP_REQUEST });

		const { data } = await axios.post(`${url}/auth/password-reset`, datas);

		if (data) {
			dispatch({ type: types.GET_OTP_SUCCESS, payload: data });
			toast.success(`Password has been updated`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Server Error") {
			dispatch();
		}
		dispatch({ type: types.GET_OTP_FAIL, payload: message });
		toast.error("Unable to complete action.", { position: "top-right " });
	}
};

export const recoveryAccount = (datas) => async (dispatch) => {
	try {
		dispatch({ type: types.POST_RECOVERY_PASSWORD_REQUEST });

		const { data } = await axios.put(`${url}/auth/reset-password`, datas);

		if (data) {
			dispatch({ type: types.POST_RECOVERY_PASSWORD_SUCCESS, payload: data });
			toast.success(`Password updated`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Server Error") {
			dispatch();
		}
		dispatch({ type: types.POST_RECOVERY_PASSWORD_FAIL, payload: message });
		toast.error("Session expired.", { position: "top-right " });
	}
};

export const broker_logout = () => (dispatch) => {
	dispatch({ type: types.AUTH_LOGOUT });
	document.location.href = "/";
};
