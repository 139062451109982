import React, { useState } from 'react'
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';
import Header from "./components/Header";
import OutsideClick from "./components/OutsideClick";
import Sidebar from "./components/Sidebar";

const BrokerPath = () => {

    const [open, setOpen] = useState(false);

	const auth = useSelector((state) => state.auth);
	const { detail } = auth;

	const toggle = (e) => {
		e.preventDefault();
		setOpen(!open);
	};

	const closeNavbar = () => {
		setOpen(false);
	};

	const location = useLocation();

	const head = location.pathname.split("/");
	const title = head[head.length - 1];

    return detail ? (
        <div className="wrapper">
			<OutsideClick handleToggle={closeNavbar}>
				<Sidebar handleToggle={closeNavbar} open={open} />
			</OutsideClick>
			<div className="main-wrapper">
				<Header openMenu={toggle} title={title} />
				<div className="">
					<Outlet />
				</div>
			</div>
		</div>
    ) : (
		<Navigate to='/' />
	);
};

export default BrokerPath