import React from 'react';
import  { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import DataTable from '../../components/Tables/BrokersTable';
import { get_all_brokers_account } from '../../redux/actions/authActions';
import Meta from '../../components/Meta';
import Preloader from '../../components/Preloader';

const ExistingBrokers = () => {
    const[ tBroker, setTbroker ] = React.useState("");
    const dispatch = useDispatch();
    
    const broker = useSelector((state) => state.broker);
    const { loading, brokers } = broker;
    const tableRef = React.useRef();

    const getbrokers = () => {
        if(brokers && brokers.length > 0){
            return brokers.filter(brok => brok.type === 1)
        }
    };

    React.useEffect(() => {
        dispatch(get_all_brokers_account());
        setTbroker(getbrokers())
    }, [dispatch]);
    
    return (
        <section className="section">
            <Meta title="All brokers" />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>All Brokers</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                                
                        </div>
                    </div>
                </div>

                {
                    loading ? (
                        <Preloader />
                    ) : (
                        <Row>
                            <Col lg={12}>
                                <Card border='light' className='p-0 mb-4'>
                                    <Card.Body className='pb-5'>
                                        {
                                            brokers && 
                                                brokers.length > 0 && (
                                                    <DataTable entries={getbrokers()} reference={tableRef} />
                                                )
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </div>
        </section>
    )
}

export default ExistingBrokers