import * as types from "../types/index";
import axios from "axios";
import toast from "react-hot-toast";
import { refresh_profile, broker_logout } from "./authActions";
import { authHeader } from "../headers";

export const account_policy = (policy, id) => async (dispatch, getState) => {
	// const url = 'http://localhost:3001/v1'
	const url = "https://nasdmobile.nasdng.com/api/v1";
	// const url = "http://146.190.205.147:3011/v1";

	try {
		dispatch({ type: types.POLICY_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.put(
			`${url}/users/accept-policy/${id}?application=${getData}`,
			policy,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({ type: types.POLICY_SUCCESS, payload: data });
			dispatch(refresh_profile(data.id));
			toast.success(`Policy has been submitted.`, {
				position: "top-right",
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Something went wrong") {
			dispatch(broker_logout());
		}
		dispatch({ type: types.POLICY_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};
