import React from "react";
import {
  Alert,
  Row,
  Col,
  ButtonGroup,
  Button,
} from "@themesberg/react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Meta from "../../components/Meta";
import { account_policy } from "../../redux/actions/policyAction";
import { settlement_account } from "../../redux/actions/settlementAction";
import ModalComponent from "../../components/ModalComponent";
import TermsAndCondition from "../../components/TermsAndCondition";

const Home = () => {
  const dispatch = useDispatch();

  const auth = useSelector((state) => state.auth);
  const { detail } = auth;

  const settlement = useSelector((state) => state.settlement);
  const { settlements } = settlement;

  /** Settlement account details */
  const [accountName, setAccountName] = React.useState();
  const [accountNumber, setAccountNumber] = React.useState();
  const [bankName, setBankName] = React.useState();
  const [openModal, setOpenModal] = React.useState(
    detail.policy === true ? false : true
  );
  const [openModal2, setOpenModal2] = React.useState(false);
  const [userPolicy, setUserPolicy] = React.useState(false);

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const id = detail.id;
    let data = {
      policy: userPolicy,
    };
    dispatch(account_policy(data, id));
    setOpenModal(false);
  };

  const cancleModal = (e) => {
    e.preventDefault();

    setOpenModal(false);
  };

  const submitSettlementAccount = (e) => {
    e.preventDefault();

    const id = detail.id;
    let data = {
      accountName: accountName,
      accountNumber: accountNumber,
      bankName: bankName,
    };
    dispatch(settlement_account(data, id));
    setOpenModal2(false);
  };

  return (
    <>
      <section className="section">
        <Meta title="Dashboard overview." />

        {detail.type === 1 && (
          <>
            {!(
              detail.accountName &&
              detail.accountNumber &&
              detail.bankName
            ) && (
              <div className="container-fluid">
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-2 width-100">
                  <Row>
                    <Col lg={12}>
                      <Alert variant="danger">
                        Kindly provide your settlement account details to access
                        all the features.
                        <ButtonGroup>
                          <Button
                            variant="outline-secondary"
                            size="sm"
                            className="px-3 mx-3"
                            onClick={() => setOpenModal2(true)}
                          >
                            Click Here
                          </Button>
                        </ButtonGroup>
                      </Alert>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </>
        )}
        <div className="container-fluid">
          <div className="title-wrapper pt-30">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="title mb-30">
                  <h2>Brokers Dashboard</h2>
                </div>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="icon-card mb-30">
                <div className="icon purple">
                  <i className="lni lni-cart-full"></i>
                </div>
                <div className="content">
                  <h6 className="mb-10">New Orders</h6>
                  <h3 className="text-bold mb-10">0</h3>
                  <p className="text-sm text-success">
                    <i className="lni lni-arrow-up"></i> +2.00%
                    <span className="text-gray">(30 days)</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="icon-card mb-30">
                <div className="icon success">
                  <i className="lni lni-dollar"></i>
                </div>
                <div className="content">
                  <h6 className="mb-10">Total Income</h6>
                  <h3 className="text-bold mb-10">0</h3>
                  <p className="text-sm text-success">
                    <i className="lni lni-arrow-up"></i> +5.45%
                    <span className="text-gray">Increased</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="icon-card mb-30">
                <div className="icon primary">
                  <i className="lni lni-credit-cards"></i>
                </div>
                <div className="content">
                  <h6 className="mb-10">Total Expense</h6>
                  <h3 className="text-bold mb-10">0</h3>
                  <p className="text-sm text-danger">
                    <i className="lni lni-arrow-down"></i> -2.00%
                    <span className="text-gray">Expense</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="icon-card mb-30">
                <div className="icon orange">
                  <i className="lni lni-user"></i>
                </div>
                <div className="content">
                  <h6 className="mb-10">New User</h6>
                  <h3 className="text-bold mb-10">0</h3>
                  <p className="text-sm text-danger">
                    <i className="lni lni-arrow-down"></i> -25.00%
                    <span className="text-gray"> Earning</span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-7">
              <div className="card-style mb-30">
                <div className="title d-flex flex-wrap justify-content-between">
                  <div className="left">
                    <h6 className="text-medium mb-10">Yearly Stats</h6>
                    <h3 className="text-bold">0</h3>
                  </div>
                  <div className="right">
                    <div className="select-style-1">
                      <div className="select-position select-sm">
                        <select className="light-bg">
                          <option value="">Yearly</option>
                          <option value="">Monthly</option>
                          <option value="">Weekly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="testing for" data-cy="test-value"></div>
                <div className="chart"></div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="card-style mb-30">
                <div
                  className="
                    title
                    d-flex
                    flex-wrap
                    align-items-center
                    justify-content-between
                  "
                >
                  <div className="left">
                    <h6 className="text-medium mb-30">Sales/Revenue</h6>
                  </div>
                  <div className="right">
                    <div className="select-style-1">
                      <div className="select-position select-sm">
                        <select className="light-bg">
                          <option value="">Yearly</option>
                          <option value="">Monthly</option>
                          <option value="">Weekly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="chart">
                  {/* <canvas
                    id="Chart2"
                    style="width: 100%; height: 400px"
                  ></canvas> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <ModalComponent
          open={openModal}
          title={`Kindly Accept Our Terms And Conditions.`}
        >
          <form>
            <div className="modal-body px-0">
              <div className="content mb-30">
                <TermsAndCondition />
              </div>
              <div className="form-check checkbox-style m-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={userPolicy}
                  onChange={() => setUserPolicy(true)}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <button
                  className="main-btn danger-btn btn-hover m-1"
                  onClick={cancleModal}
                >
                  Decline
                </button>
                <button
                  className="main-btn primary-btn btn-hover m-1"
                  style={{ backgroundColor: "#252063", color: "#ffffff" }}
                  onClick={submitHandler}
                >
                  Accept
                </button>
              </div>
            </div>
          </form>
        </ModalComponent>
        <ModalComponent
          open={openModal2}
          title={`Kindly provide you settlement account details.`}
        >
          <form onSubmit={submitSettlementAccount}>
            <div className="modal-body px-0">
              <div className="row">
                <div className="col-12">
                  <div className="input-style-1">
                    <label>Account Name</label>
                    <input
                      type="text"
                      placeholder="Account Name"
                      value={accountName}
                      onChange={(e) => setAccountName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-style-1">
                    <label>Account Number</label>
                    <input
                      type="text"
                      placeholder="Account Number"
                      value={accountNumber}
                      onChange={(e) => setAccountNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="input-style-1">
                    <label>Bank Name</label>
                    <input
                      type="text"
                      placeholder="Bank Name"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between mt-4">
                <button
                  type="button"
                  className="main-btn danger-btn btn-hover m-1"
                  onClick={() => setOpenModal2(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="main-btn primary-btn btn-hover m-1"
                  style={{ backgroundColor: "#252063", color: "#ffffff" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </ModalComponent>
      </section>
    </>
  );
};

export default Home;
