export const SETTLEMENT_REQUEST = "SETTLEMENT_REQUEST";
export const SETTLEMENT_SUCCESS = "SETTLEMENT_SUCCESS";
export const SETTLEMENT_FAIL = "SETTLEMENT_FAIL";
export const SETTLEMENT_RESET = "SETTLEMENT_RESET";

export const POUCHII_TRANSACTION_REQUEST = "POUCHII_TRANSACTION_REQUEST";
export const POUCHII_TRANSACTION_SUCCESS = "POUCHII_TRANSACTION_SUCCESS";
export const POUCHII_TRANSACTION_FAIL = "POUCHII_TRANSACTION_FAIL";
export const POUCHII_TRANSACTION_RESET = "POUCHII_TRANSACTION_RESET";

export const POUCHII_UNLOCK_TRANSACTION_REQUEST =
  "POUCHII_UNLOCK_TRANSACTION_REQUEST";
export const POUCHII_UNLOCK_TRANSACTION_SUCCESS =
  "POUCHII_UNLOCK_TRANSACTION_SUCCESS";
export const POUCHII_UNLOCK_TRANSACTION_FAIL =
  "POUCHII_UNLOCK_TRANSACTION_FAIL";
export const POUCHII_UNLOCK_TRANSACTION_RESET =
  "POUCHII_UNLOCK_TRANSACTION_RESET";

export const POUCHII_RELEASE_TRANSACTION_REQUEST =
  "POUCHII_RELEASE_TRANSACTION_REQUEST";
export const POUCHII_RELEASE_TRANSACTION_SUCCESS =
  "POUCHII_RELEASE_TRANSACTION_SUCCESS";
export const POUCHII_RELEASE_TRANSACTION_FAIL =
  "POUCHII_RELEASE_TRANSACTION_FAIL";
export const POUCHII_RELEASE_TRANSACTION_RESET =
  "POUCHII_RELEASE_TRANSACTION_RESET";

export const GET_ALL_BANKS_REQUEST = "GET_ALL_BANKS_REQUEST";
export const GET_ALL_BANKS_SUCCESS = "GET_ALL_BANKS_SUCCESS";
export const GET_ALL_BANKS_FAIL = "GET_ALL_BANKS_FAIL";
export const GET_ALL_BANKS_RESET = "GET_ALL_BANKS_RESET";
