import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { recoveryAccount } from '../redux/actions/authActions'
import { useSelector, useDispatch } from 'react-redux';
import Meta from '../components/Meta';


const RecoverPassword = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ email, setEmail ] = React.useState("");
    const [ passwordx, setPassword ] = React.useState("");

    const passwordRecovery = useSelector((state) => state.passwordRecovery);
	const { loading, recovery } = passwordRecovery;

    const submitHandler = (e) => {
        e.preventDefault();
        let data = {
            email, 
            password: passwordx
        }
        dispatch(recoveryAccount(data))

    }

    React.useEffect(() => {
        if(recovery) {
            navigate('/')
        }
    }, [recovery, navigate])

    return (
        <>
            <Meta title="Recover Password" />
            <section className="section">
                <div className="container">

                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="signinc-wrapper mt-5">
                                <div className="form-wrapper">
                                    <div className="navbar-logo text-center mb-5">
                                        <img src="https://nasdng.com/wp-content/uploads/2018/10/NASD-LOGO-PLC.png" alt="logo" />
                                    </div>
                                    <h2 className="mb-15">Enter your email and new password to continue.</h2>
                                    <form action="#">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="input-style-1">
                                                    <label>Email</label>
                                                    <input 
                                                        type="email" 
                                                        placeholder="Email" 
                                                        value={email}
                                                        onChange={(e) => setEmail(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="input-style-1">
                                                    <label>New Password</label>
                                                    <input 
                                                        id="password"
                                                        type="password" 
                                                        placeholder="New Password" 
                                                        value={passwordx}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div
                                                    className="
                                                        button-group
                                                        d-flex
                                                        justify-content-center
                                                        flex-wrap
                                                    "
                                                >
                                                    <button
                                                        className="main-btn primary-btn btn-hover w-100 text-center"
                                                        style={{ backgroundColor: "#252063", color: "#ffffff"}}
                                                        onClick={submitHandler}
                                                        disabled={
                                                            email && passwordx && !loading
                                                                ? false
                                                                : true
                                                        }
                                                    >
                                                        Submit{" "}
                                                        {loading && (
                                                            <i className="fas fa-spinner fa-spin"></i>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default RecoverPassword