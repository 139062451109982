import React from 'react';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import PasswordAndConfirmPasswordValidation from '../../components/PasswordAndConfirmPasswordValidation';
import Meta from '../../components/Meta';

const ChangePassword = () => {

    return (
        <section className="section">
            <Meta title="Change your password" />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Change your brokers password.</h2>
                            </div>
                        </div>
                        <div
                            className='col-md-6'
                        >
                            
                        </div>
                    </div>
                </div>

                {/* Brokers Informations */}
                <Row>
                    <Col lg={6}>
                        <Card border='light' className='p-0 mb-4'>
                            <Card.Body className='pb-5'>
                                <Row>
                                    {/* Password Validation Component */}
                                    <Col lg={12}>
                                        <PasswordAndConfirmPasswordValidation />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default ChangePassword