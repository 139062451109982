import * as types from "../types/index";
import { authHeader } from "../headers";
import { broker_logout } from "./authActions";
import axios from "axios";
import toast from "react-hot-toast";

export const get_single_broker_buy_trades =
	(code) => async (dispatch, getState) => {
		const url = "https://apis.nasdotcng.com/api/broker-trades";
		let datas;

		try {
			dispatch({ type: types.ALL_INVESTOR_TRADE_REQUEST });

			const {
				auth: { detail },
			} = getState();

			console.log(detail.brokerCode, "Admin Resent Details");

			const { data } = await axios.get(`${url}/${code}`, {
				headers: authHeader("ileyvcbi8k7262o82l8y3h20o8d3yv2o3723g"),
			});

			const filteredData = () => {
				if (data && data.length > 0) {
					return data.filter((d) => d["FROM MEMBER"] === code);
				}
			};

			datas = filteredData();

			if (datas) {
				dispatch({ type: types.ALL_INVESTOR_TRADE_SUCCESS, payload: datas });
			}
		} catch (error) {
			const message = error.response.data.error
				? error.response.data.error
				: "Something went wrong";
			if (message === "Something went wrong") {
				dispatch(broker_logout());
			}
			dispatch({ type: types.ALL_INVESTOR_TRADE_FAIL, payload: message });
			toast.error(message, { position: "top-right " });
		}
	};

export const get_single_broker_sell_trades =
	(code) => async (dispatch, getState) => {
		const url = "https://apis.nasdotcng.com/api/broker-trades";

		let datas;

		try {
			dispatch({ type: types.ALL_INVESTOR_SELL_TRADE_REQUEST });

			const {
				auth: { detail },
			} = getState();

			const { data } = await axios.get(`${url}/${code}`, {
				headers: authHeader("ileyvcbi8k7262o82l8y3h20o8d3yv2o3723g"),
			});

			const filteredData = () => {
				if (data && data.length > 0) {
					return data.filter((d) => d["TO MEMBER"] === code);
				}
			};

			datas = filteredData();

			if (datas) {
				dispatch({
					type: types.ALL_INVESTOR_SELL_TRADE_SUCCESS,
					payload: datas,
				});
			}
		} catch (error) {
			const message = error.response.data.error
				? error.response.data.error
				: "Something went wrong";
			if (message === "Something went wrong") {
				dispatch(broker_logout());
			}
			dispatch({ type: types.ALL_INVESTOR_SELL_TRADE_FAIL, payload: message });
			toast.error(message, { position: "top-right " });
		}
	};

export const admin_all_trades_request =
	({ status, search }) =>
	async (dispatch, getState) => {
		// const url = 'http://localhost:3001/v1'
		const url = "https://nasdmobile.nasdng.com/api/v1";
		// const url = "http://146.190.205.147:3011/v1";

		try {
			dispatch({ type: types.ADMIN_INVESTOR_TRADE_REQUEST });

			const {
				auth: { detail },
			} = getState();

			const getData = window.localStorage.getItem("devoper");

			const { data } = await axios.get(
				`${url}/order?application=${getData}&status=${status}&search=${search}`,
				{
					headers: authHeader(detail.token),
				}
			);

			if (data) {
				dispatch({ type: types.ADMIN_INVESTOR_TRADE_SUCCESS, payload: data });
			}
		} catch (error) {
			const message = error.response.data.error
				? error.response.data.error
				: "Something went wrong";
			if (message === "Something went wrong") {
				dispatch(broker_logout());
			}
			dispatch({ type: types.ADMIN_INVESTOR_TRADE_FAIL, payload: message });
			toast.error(message, { position: "top-right " });
		}
	};

export const get_all_transaction_history = () => async (dispatch, getState) => {
	// const url = 'http://localhost:3001/v1'
	const url = "https://nasdmobile.nasdng.com/api/v1";
	// const url = "http://146.190.205.147:3011/v1";

	try {
		dispatch({ type: types.INVESTOR_TRANSACTION_HISTORY_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.get(
			`${url}/transaction?application=${getData}`,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({
				type: types.INVESTOR_TRANSACTION_HISTORY_SUCCESS,
				payload: data,
			});
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Something went wrong") {
			dispatch(broker_logout());
		}
		dispatch({
			type: types.INVESTOR_TRANSACTION_HISTORY_FAIL,
			payload: message,
		});
		toast.error(message, { position: "top-right " });
	}
};

export const single_transaction_history =
	(id) => async (dispatch, getState) => {
		// const url = 'http://localhost:3001/v1'
		const url = "https://nasdmobile.nasdng.com/api/v1";
		// const url = "http://146.190.205.147:3011/v1";

		try {
			dispatch({ type: types.SINGLE_INVESTOR_TRANSACTION_HISTORY_REQUEST });

			const {
				auth: { detail },
			} = getState();

			const getData = window.localStorage.getItem("devoper");

			const { data } = await axios.get(
				`${url}/transaction/${id}?application=${getData}`,
				{
					headers: authHeader(detail.token),
				}
			);

			if (data) {
				dispatch({
					type: types.SINGLE_INVESTOR_TRANSACTION_HISTORY_SUCCESS,
					payload: data,
				});
			}
		} catch (error) {
			const message = error.response.data.error
				? error.response.data.error
				: "Something went wrong";
			//   if (message === 'Network Issue') {
			//     dispatch(broker_logout());
			//   }
			dispatch({
				type: types.SINGLE_INVESTOR_TRANSACTION_HISTORY_FAIL,
				payload: message,
			});
			toast.error(message, { position: "top-right " });
		}
	};

export const investor_wallet_balance = (id) => async (dispatch, getState) => {
	// const url = 'http://localhost:3001/v1'
	const url = "https://nasdmobile.nasdng.com/api/v1";
	// const url = "http://146.190.205.147:3011/v1";

	try {
		dispatch({ type: types.SINGLE_INVESTOR_WALLET_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.get(
			`${url}/wallet/${id}?application=${getData}`,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({ type: types.SINGLE_INVESTOR_WALLET_SUCCESS, payload: data });
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		// if (message === 'Network Issue') {
		//   dispatch(broker_logout());
		// }
		dispatch({ type: types.SINGLE_INVESTOR_WALLET_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};

export const release_funds = (datas) => async (dispatch, getState) => {
	// const url = 'http://localhost:3001/v1'
	const url = "https://nasdmobile.nasdng.com/api/v1";
	// const url = "http://146.190.205.147:3011/v1";

	try {
		dispatch({ type: types.RELEASE_FUNDS_REQUEST });

		const {
			auth: { detail },
		} = getState();

		const getData = window.localStorage.getItem("devoper");

		const { data } = await axios.post(
			`${url}/wallet/release-fund?application=${getData}`,
			datas,
			{
				headers: authHeader(detail.token),
			}
		);

		if (data) {
			dispatch({ type: types.RELEASE_FUNDS_SUCCESS, payload: data });
		}
	} catch (error) {
		const message = error.response.data.error
			? error.response.data.error
			: "Something went wrong";
		if (message === "Network Issue") {
			dispatch(broker_logout());
		}
		dispatch({ type: types.RELEASE_FUNDS_FAIL, payload: message });
		toast.error(message, { position: "top-right " });
	}
};
