import React from 'react';
import { Row, Col, Card, Tab, Tabs } from '@themesberg/react-bootstrap';
import DataTable from '../../components/Tables/DataTable';
import CorporateInvestorTable from '../../components/Tables/CorporateInvestorTable';
import { useDispatch, useSelector } from 'react-redux';
import Meta from '../../components/Meta';
import { get_all_individual_investors,
         get_all_corporate_investors
 } from '../../redux/actions/investorsActions';
import Preloader from '../../components/Preloader';


const NewInvestors = () => {
    const dispatch = useDispatch();
    const tableRef = React.useRef();

    const [ saveInvestor, setSaveInvestor ] = React.useState(null);

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const individual = useSelector((state) => state.individual);
    const { loading, indivInvestor } = individual;
    
    const corporate = useSelector((state) => state.corporate);
    const { loading: loadingCorp, corpInvestor } = corporate;

    const getIndividualInvestors = () => {
        if(indivInvestor && indivInvestor.length > 0){
            return indivInvestor.filter(investor => (
                investor?.isExisting === false && 
                investor?.accountType === "individual" &&
                (investor?.investorStatus === 1 || investor?.investorStatus === 2) &&
                (investor?.brokerCode === detail?.brokerCode || investor?.brokercontactname === detail?.brokerCode)
            ))
        }
    };

    const individual_investor = getIndividualInvestors();

    const getCorporateInvestors = () => {
        if(corpInvestor && corpInvestor.length > 0){
            return corpInvestor.filter(investor => (
                investor?.isExisting === false && 
                investor?.accountType === "corporate" &&
                (investor?.investorStatus === 1 || investor?.investorStatus === 2) &&
                (investor?.brokerCode === detail?.brokerCode || investor?.brokercontactname === detail?.brokerCode)
            ))
        }
    };

    const corporate_investor = getCorporateInvestors();
    
    React.useEffect(() => {
        dispatch(get_all_individual_investors());
        dispatch(get_all_corporate_investors());
        setSaveInvestor(getIndividualInvestors())
    },[ dispatch ])

    return (
        <section className="section">
            <Meta title="New added investors" />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>New Investors</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                                
                        </div>
                    </div>
                </div>

                <Tabs
                    defaultActiveKey="individual"
                    className="mb-3"
                >
                    <Tab eventKey="individual" title="Individual Investors">
                        {
                            loading ? 
                                (
                                    <Preloader />
                                ) :
                                (
                                    <Row>
                                        <Col lg={12}>
                                            <Card border='light' className='p-0 mb-4'>
                                                <Card.Body className='pb-5'>
                                                        {
                                                            individual_investor && 
                                                                individual_investor.length > 0 ? (
                                                                    <DataTable entries={individual_investor} reference={tableRef} />
                                                                ) : ( <h6>You do not have any registered investor.</h6>)
                                                        }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Tab>
                    <Tab eventKey="corporate" title="Corporate Investors">
                        {
                            loadingCorp ?
                                (
                                    <Preloader />
                                ) : (
                                    <Row>
                                        <Col lg={12}>
                                            <Card border='light' className='p-0 mb-4'>
                                                <Card.Body className='pb-5'>
                                                    {
                                                        corporate_investor && 
                                                            corporate_investor.length > 0 ? (
                                                                <CorporateInvestorTable entries={corporate_investor} reference={tableRef} />
                                                            ) : ( <h6>You do not have any registered corporate investor.</h6>)
                                                    }
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                )
                        }
                    </Tab>
                </Tabs>
            </div>
        </section>
    )
}

export default NewInvestors