import React from 'react'

const TermsAndCondition = () => {
    return (
        <div>
            <h4>Terms and Conditions</h4>
            <h6>Welcome to NASD Brokers Platform!</h6>
            <br />
            <p className="text-sm">
                These terms and conditions outline the rules and regulations for the use of NASD Brokers Platform.
                By using this app we assume you accept these terms and conditions. Do not continue to use  the NASD Brokers Platform if you do not agree to take all of the terms and conditions stated on this page.
                The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services, in accordance with and subject to, prevailing law of Nigeria. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
                License
                Unless otherwise stated, NASD Brokers Platform and/or its licensors own the intellectual property rights for all material on NASD Brokers Platform. All intellectual property rights are reserved. You may access this from NASD Brokers Platform for your own personal use subject to restrictions set in these terms and conditions.
                You must not:
                <br />
                <ul>
                    <li>Republish material from NASD Brokers Platform</li>
                    <li>Sell, rent or sub-license material from NASD Brokers Platform</li>
                    <li>Reproduce, duplicate or copy material from NASD Brokers Platform</li>
                    <li>Redistribute content from NASD Brokers Platform</li>
                </ul>
            </p>
            <br />
            <h6>Client Obligations</h6>
            <p className="text-sm">   
                By agreeing to these terms and conditions, a client agrees to carry out the following required obligations, on the NASD Brokers Platform.

                You must: 
                <br />
                <ul>
                    <li>Ensure the information provided on the NASD Brokers Platform is accurate as required by Law.</li>
                    <li>Promptly attend to client onboarding and notify NASD of any challenge for follow up.</li>
                    <li>Carry out all KYC requirements for Investors on the platform before onboarding for trading.</li>
                </ul>
            </p>
            <br />
            <h6>Content Liability</h6>
            <p className="text-sm"> 
                We shall not be held responsible for any content that appears on your App. You agree to protect and defend us against all claims that are rising on our App. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
            </p>
            <br />
            <h6>Reservation of Rights</h6>
            <p className="text-sm"> 
                We reserve the right to request that you remove all links or any particular link to our App. You approve to immediately remove all links to our App upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our App, you agree to be bound to and follow these linking terms and conditions.
            </p>
            <br />
            <h6>Disclaimer</h6>
            <p className="text-sm">
                To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our App and the use of this website. Nothing in this disclaimer will:
                <br />
                <ul>
                    <li>limit or exclude our or your liability for death or personal injury;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ul>

                The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.
                As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.
            </p>   
        </div>
    )
}

export default TermsAndCondition