import React from 'react';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import TransferTable from '../../components/Tables/TransferTable';
import Meta from '../../components/Meta';
import Preloader from '../../components/Preloader';

const Transfers = () => {
    const [data, setData] = React.useState(null)
    React.useEffect(() => {
        const apiUrl = 'https://api.paystack.co/transfer';
        const authToken = 'sk_live_c23961f26c87f86a6c8a19c93494d894c1f75712';

        const headers = {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json',
        };
    
        fetch(apiUrl, {
          method: 'GET',
          headers: headers,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
            }
            return response.json();
          })
          .then((responseData) => {
            setData(responseData);
          })
          .catch((error) => {
            console.error('Fetch error:', error);
          });
      }, []);

    console.log(data);

  return (
    <>
            <Meta title='All Transfers' />
            <section className="section">
                <div className="container-fluid">
                    <div className="title-wrapper pt-30">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <div className="title mb-30">
                                    <h2>All Transfers</h2>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>

            
                    {
                        !data || data === null ?
                            (
                                <Preloader />
                            )   : (
                                <Row>
                                    <Col lg={12}>
                                        <Card border='light' className='p-0 mb-4'>
                                            <Card.Body className='pb-5'>
                                                {
                                                    data && 
                                                        data.data.length > 0 ? (
                                                            <TransferTable entries={data.data} />
                                                        ) : (
                                                            <h6
                                                                style={{ color: "#252063" }}
                                                            >You do not have any transfer.</h6>
                                                        )
                                                }
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            ) 
                    }
                </div>
            </section>
        </>
  )
}

export default Transfers