import { Row, Col, Card, Form, ButtonGroup } from "@themesberg/react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { Link } from "react-router-dom";
import Pagination from "react-bootstrap-table2-paginator";
import * as Paginator from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import ExportCSV from "../ExportCSV";
import React from "react";

const AllBuyTradesTable = ({ entries, ref }) => {
  const navigate = useNavigate();

  const singleTradeDetails = () => {
    navigate(`/dashboard/trade-request/56`);
  };

  const formatValue = (cell) => {
    const fixed = (Math.round(cell * 100) / 100).toFixed(4);
    return fixed;
  };

  const formatDate = (cell) => {
    if (!cell) return " ";

    let date = new Date(cell);

    return (
      date.getDate() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getFullYear() +
      "" +
      " [" +
      date.getHours() +
      ":" +
      date.getMinutes() +
      "]"
    );
  };

  const actionBtn = (cell) => {
    console.log(cell);
    return (
      <div className="dropdown">
        <span className="dropbtn">
          <Link to={`/dashboard/view-trade-request/${cell}`}>
            <FontAwesomeIcon
              icon={faEye}
              size={12}
              className="me-2"
              style={{ color: "black", fontSize: ".8rem" }}
            />
          </Link>
        </span>
      </div>
    );
  };

  const columns = [
    { dataField: "id", text: "ID", hidden: true },
    {
      dataField: "createdAt",
      text: "Date",
      formatter: (cell) => formatDate(cell),
    },
    { dataField: "accountNo", text: "Account Number", sort: true },
    { dataField: "clientName", text: "Client Name", sort: true },
    { dataField: "brokerCode", text: "Broker Code", sort: true },
    { dataField: "security", text: "Security", sort: true },
    { dataField: "units", text: "Quantity", sort: true },
    {
      dataField: "basePrice",
      text: "Trade Price",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "costPrice",
      text: "Value",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "nasdFee",
      text: "NASD Fee",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "nasdFeeVat",
      text: "NASD VAT",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "secFee",
      text: "Sec Fee",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "xAlert",
      text: "Alert Fee",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "xAlertVat",
      text: "Alert VAT",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "brokerCommission",
      text: "Broker Commission",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "brokerCommissionVat",
      text: "Broker VAT",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "stampDuties",
      text: "Stamp Duties",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "totalTradeCost",
      text: "Total Trade Cost",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "paystacktransferFee",
      text: "Paystack Transfer Fee",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "paystackServiceCharge",
      text: "Paystack Service Fee",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    {
      dataField: "finalTotal",
      text: "Investor Wallet Debit",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    { dataField: "status", text: "Trade Status", sort: true },
    {
      dataField: "totalTradeCost",
      text: "Broker Payout",
      formatter: (cell) => formatValue(cell),
      sort: true,
    },
    { dataField: "", text: "Broker Payout Status", sort: true },
    {
      dataField: "id",
      text: "View Trade",
      formatter: (cell) => actionBtn(cell),
    },
  ];

  const customTotal = (from, to, size) => (
    <div>
      Showing {from} to {to} of {size} entries
    </div>
  );

  const customSizePerPage = (props) => {
    const { options, currentSizePerPage, onSizePerPageChange } = props;

    const onPageChange = (e) => {
      const page = e.target.value;
      onSizePerPageChange(page);
    };

    return (
      <Row>
        <Col xs="auto">
          <Form.Select
            value={currentSizePerPage}
            onChange={onPageChange}
            className="pe-5"
          >
            {options.map((o) => (
              <option key={o.page} value={o.page}>
                {o.text}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs="auto" className="d-flex align-items-center">
          entries per page
        </Col>
      </Row>
    );
  };

  return (
    <ToolkitProvider
      keyField="id"
      search={true}
      columns={columns}
      data={entries}
      wrapperClasses="table-responsive"
    >
      {({ baseProps, searchProps, csvProps }) => (
        <>
          <div className="d-flex justify-content-end align-items-center mb-2">
            <div className="d-flex ml-auto my-3">
              <ButtonGroup>
                <ExportCSV
                  variant="outline-secondary"
                  size="sm"
                  className="px-3"
                  {...csvProps}
                >
                  Export CSV
                </ExportCSV>
                <ExportCSV
                  variant="outline-secondary"
                  size="sm"
                  className="px-3"
                >
                  Export PDF
                </ExportCSV>
              </ButtonGroup>
            </div>
          </div>

          <Paginator.PaginationProvider
            pagination={Pagination({
              custom: true,
              showTotal: true,
              alwaysShowAllBtns: true,
              totalSize: entries.length,
              paginationTotalRenderer: customTotal,
              sizePerPageRenderer: customSizePerPage,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <Card>
                <div className="table-responsive py-2">
                  <Card.Header>
                    <Row>
                      <Col xs={12} md={6} className="py-1">
                        <Paginator.SizePerPageDropdownStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-md-end py-1"
                      >
                        <Search.SearchBar {...searchProps} />
                      </Col>
                    </Row>
                  </Card.Header>

                  <BootstrapTable
                    ref={ref}
                    bootstrap4
                    {...baseProps}
                    {...paginationTableProps}
                    bodyClasses="border-1"
                    rowClasses="border-bottom"
                    classes="table-bordered dataTable-table table-striped"
                    rowStyle={{ whiteSpace: "nowrap" }}
                  />

                  <Card.Footer>
                    <Row>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex align-items-center py-1"
                      >
                        <Paginator.PaginationTotalStandalone
                          {...paginationProps}
                        />
                      </Col>
                      <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-md-end align-items-center mb-0 py-1"
                      >
                        <Paginator.PaginationListStandalone
                          {...paginationProps}
                        />
                      </Col>
                    </Row>
                  </Card.Footer>
                </div>
              </Card>
            )}
          </Paginator.PaginationProvider>
        </>
      )}
    </ToolkitProvider>
  );
};

export default AllBuyTradesTable;
