import React from "react";
import {
	Row,
	Card,
	Col,
	Button,
	ButtonGroup,
} from "@themesberg/react-bootstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jsPDF from "jspdf";
import * as htmlToImage from "html-to-image";
import Meta from "../../components/Meta";
import Image from "react-image-enlarger";
import ModalComponent from "../../components/ModalComponent";
import Preloader from "../../components/Preloader";
import {
	get_single_individual_investors,
	confirm_investors_account_status,
} from "../../redux/actions/investorsActions";

const SingleInvestor = () => {
	const params = useParams();
	const dispatch = useDispatch();

	// Modal State
	const [openModal, setOpenModal] = React.useState(false);
	const [confirmModal, setConfirmModal] = React.useState(false);

	const [showChnForm, setShowChnForm] = React.useState(false);
	const [showAccountForm, setShowAccountForm] = React.useState(true);
	const [confirmAccountNumber, setConfirmAccountNumber] = React.useState();

	// Images States
	const [zoomed, setZoomed] = React.useState(false);
	const [zoomed2, setZoomed2] = React.useState(false);
	const [zoomed3, setZoomed3] = React.useState(false);
	const [zoomed4, setZoomed4] = React.useState(false);

	/** New investor account details */
	const [accountNumbers, setAccountNumbers] = React.useState("");

	const status = useSelector((state) => state.status);
	const { loading } = status;

	const singleIndv = useSelector((state) => state.singleIndv);
	const { singleIndivInvestor } = singleIndv;

	const [investorsName, setInvestorsName] = React.useState(
		confirmAccountNumber ? confirmAccountNumber.SHAREHOLDERSNAME : null
	);

	const [investorsChn, setInvestorsChn] = React.useState(
		confirmAccountNumber ? confirmAccountNumber.CHN : null
	);

	// Getting the type of a Base64 String
	const base64Data = singleIndivInvestor
		? `data:image/png;base64, ${singleIndivInvestor.useriddocument}`
		: "";
	const [type] = base64Data.split(";")[0].split("/");

	const investorName = singleIndivInvestor
		? `${singleIndivInvestor.fullName}`
		: "Investors Profile";

	const toggleModal = () => {
		setOpenModal(false);
	};

	const toggleConfirmModal = () => {
		setConfirmModal(false);
	};

	const toggleChnForm = () => {
		setShowChnForm(!showChnForm);
		setShowAccountForm(false);
	};

	const toggleAccountForm = () => {
		setShowAccountForm(true);
		setShowChnForm(false);
	};

	const submitInvestorAccount = (e) => {
		e.preventDefault();
		const ACCOUNTNUMBER = accountNumbers;
		if (ACCOUNTNUMBER.length > 4) {
			fetchAccountInfo(ACCOUNTNUMBER);
		}
	};

	const modalOptions = (e) => {
		e.preventDefault();

		setConfirmModal(true);
		setOpenModal(false);
	};

	const confirmModalOptions = (e) => {
		e.preventDefault();
		const id = params.id;
		const data = {
			status: 2,
			accountNumber: accountNumbers,
		};

		dispatch(confirm_investors_account_status(data, id));
		setConfirmModal(false);
		setInvestorsChn("");
		setInvestorsName("");
	};

	const printDocument = () => {
		htmlToImage
			.toPng(document.getElementById("divToPrint"), { quality: 1.0 })
			.then(function (dataUrl) {
				let link = document.createElement("a");
				link.download = "my-image-name.jpeg";
				const pdf = new jsPDF();
				const imgProps = pdf.getImageProperties(dataUrl);
				const pdfWidth = pdf.internal.pageSize.getWidth();
				const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
				pdf.addImage(dataUrl, "PNG", 0, 0, pdfWidth, pdfHeight);
				pdf.save(`${investorName}.pdf`);
			});
	};

	const checkForString = () => {
		if (confirmAccountNumber && singleIndivInvestor) {
			let name = (
				singleIndivInvestor?.fullName || singleIndivInvestor?.accountName
			).split(" ");
			const newData = name[Math.floor(Math.random() * name.length)];
			const checkCharacter =
				confirmAccountNumber?.SHAREHOLDERSNAME.toLowerCase().includes(
					newData.toLowerCase()
				);

			return checkCharacter;
		}
	};

	React.useEffect(() => {
		dispatch(get_single_individual_investors(params.id));
	}, [dispatch, params.id]);

	async function fetchAccountInfo(accountNumber) {
		const apiUrl = `https://apis.nasdotcng.com/api/account/${accountNumber}`;

		try {
			const response = await fetch(apiUrl);
			if (!response.ok) {
				throw new Error(
					`Failed to fetch account information. Status: ${response.status}`
				);
			}

			const data = await response.json();
			setConfirmAccountNumber(data);
			return data;
		} catch (error) {
			console.error("Error fetching account information:", error.message);
			throw error;
		}
	}

	return (
		<section className="section">
			<Meta title="Single Investor Information." />
			<div className="container-fluid">
				<Card border="light" className="mb-3 mt-4">
					<Card.Body>
						<div className="title-wrapper pt-1">
							<div className="d-flex align-items-center justify-content-between investor-header">
								<div className="">
									<div className="title mb-10">
										<h2>Investors Information</h2>
									</div>
								</div>
								{!singleIndivInvestor?.isExisting && (
									<div
										style={{
											paddingBottom: "5px",
										}}
										className="download_btn"
									>
										<ButtonGroup>
											<a
												href={
													"data:application/pdf;base64, " +
													singleIndivInvestor?.dcsDocument
												}
												download={`${
													singleIndivInvestor?.fullName ||
													singleIndivInvestor?.accountName
												}.PDF`}
												className={`px-3 btn btn-outline-secondary btn-sm ${
													!singleIndivInvestor?.dcsDocument ? "disabled" : ""
												}`}
											>
												Download DCS
											</a>
										</ButtonGroup>
									</div>
								)}
								{singleIndivInvestor && !singleIndivInvestor.chn && (
									<div
										style={{
											paddingBottom: "5px",
										}}
									>
										{singleIndivInvestor?.isExisting ? (
											<ButtonGroup>
												<Button
													variant="outline-secondary"
													size="sm"
													className="px-3"
													onClick={() => setOpenModal(true)}
												>
													Validate Account Number
												</Button>
											</ButtonGroup>
										) : (
											<ButtonGroup>
												<Button
													variant="outline-secondary"
													size="sm"
													className="px-3"
													onClick={() => setOpenModal(true)}
													// disabled={!singleIndivInvestor.dcsDocument ? true : false}
												>
													Validate Account Number
												</Button>
											</ButtonGroup>
										)}
									</div>
								)}

								<div
									style={{
										paddingBottom: "5px",
									}}
								>
									<Button
										variant="outline-secondary"
										size="sm"
										className=""
										onClick={() => printDocument()}
									>
										Export PDF
									</Button>
								</div>
							</div>
						</div>
					</Card.Body>
				</Card>
				{singleIndivInvestor &&
				(singleIndivInvestor.fullName || singleIndivInvestor.accountName) ? (
					<div id="divToPrint">
						<Row>
							<Col lg={6}>
								<h6 style={{ margin: "20px 0px" }}>Personal Information</h6>
								<Card border="light" className="p-0 mb-4">
									<Card.Body className="pb-2">
										<Row>
											{singleIndivInvestor.fullName ? (
												<Col lg={6}>
													<div className="input-style-1">
														<label>Name</label>
														<input
															type="text"
															value={singleIndivInvestor?.fullName.toUpperCase()}
															disabled
														/>
													</div>
												</Col>
											) : (
												<Col lg={6}>
													<div className="input-style-1">
														<label>Name</label>
														<input
															type="text"
															value={singleIndivInvestor?.accountName.toUpperCase()}
															disabled
														/>
													</div>
												</Col>
											)}

											<Col lg={6}>
												<div className="input-style-1">
													<label>Email</label>
													<input
														type="email"
														value={
															singleIndivInvestor.emailAddress ||
															singleIndivInvestor.contactEmailAddress
														}
														disabled
													/>
												</div>
											</Col>
										</Row>

										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Phone Number</label>
													<input
														type="text"
														value={
															singleIndivInvestor.phoneNumber ||
															singleIndivInvestor.contactPhoneNumber
														}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>
														Address{" "}
														{singleIndivInvestor?.isExisting && (
															<span style={{ color: "red" }}>*</span>
														)}
													</label>
													<input
														type="text"
														value={
															singleIndivInvestor.address ||
															singleIndivInvestor.contactPersonAddress
														}
														disabled
													/>
												</div>
											</Col>
										</Row>
									</Card.Body>
								</Card>

								<h6 style={{ margin: "20px 0px" }}>Bank Information</h6>
								<Card border="light" className="p-0 mb-4">
									<Card.Body className="pb-2">
										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Account Name</label>
													<input
														type="text"
														value={singleIndivInvestor.bankAccountName}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Account Number</label>
													<input
														type="email"
														value={singleIndivInvestor.bankAccountNo}
														disabled
													/>
												</div>
											</Col>
										</Row>

										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Bank Name</label>
													<input
														type="email"
														value={singleIndivInvestor.bankName}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>BVN</label>
													<input
														type="email"
														value={singleIndivInvestor.bvn}
														disabled
													/>
												</div>
											</Col>
										</Row>
									</Card.Body>
								</Card>

								<h6 style={{ margin: "20px 0px" }}>Next Of Kin</h6>
								<Card border="light" className="p-0 mb-4">
									<Card.Body className="pb-2">
										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Account Created Date</label>
													<input
														type="text"
														value={singleIndivInvestor.bankAccountCreatedOn}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Next Of Kin Name</label>
													<input
														type="email"
														value={singleIndivInvestor.nextOfKinName}
														disabled
													/>
												</div>
											</Col>
										</Row>

										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Next Of Kin Phone</label>
													<input
														type="text"
														value={singleIndivInvestor.nextOfKinPhoneNumber}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Next Of Kin Email</label>
													<input
														type="email"
														value={singleIndivInvestor.nextOfKinEmail}
														disabled
													/>
												</div>
											</Col>
											{singleIndivInvestor?.isExisting && (
												<Col lg={12}>
													<span style={{ color: "red", fontSize: ".7rem" }}>
														Please note, for an existing investor, the
														asterisk(*) fields are coming from CSCS.
													</span>
												</Col>
											)}
										</Row>
									</Card.Body>
								</Card>
							</Col>

							<Col lg={6}>
								<h6 style={{ margin: "20px 0px" }}>Broker Information</h6>
								<Card border="light" className="p-0 mb-4">
									<Card.Body className="pb-2">
										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>ID Type</label>
													<input
														type="text"
														value={singleIndivInvestor.idType}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Broker ID</label>
													<input
														type="email"
														value={singleIndivInvestor.brokerid}
														disabled
													/>
												</div>
											</Col>
										</Row>

										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Broker Name</label>
													<input
														type="text"
														value={singleIndivInvestor.brokername}
														disabled
													/>
												</div>
											</Col>

											<Col lg={6}>
												<div className="input-style-1">
													<label>Broker Phone</label>
													<input
														type="text"
														value={singleIndivInvestor.brokerphone}
														disabled
													/>
												</div>
											</Col>
										</Row>

										<Row>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Broker Contact Name</label>
													<input
														type="text"
														value={singleIndivInvestor.brokercontactname}
														disabled
													/>
												</div>
											</Col>
											<Col lg={6}>
												<div className="input-style-1">
													<label>Broker Email</label>
													<input
														type="email"
														value={singleIndivInvestor.brokeremail}
														disabled
													/>
												</div>
											</Col>
										</Row>
									</Card.Body>
								</Card>

								<h6 style={{ margin: "20px 0px" }}>Investors Document</h6>
								<Card border="light" className="p-0 mb-4">
									<Card.Body className="pb-2">
										<Row>
											<Col lg={6}>
												{singleIndivInvestor.useriddocument && (
													<div className="input-style-1">
														<label>Investors ID Card</label>
														<Image
															style={{ width: "150px", height: "auto" }}
															zoomed={zoomed3}
															src={`${singleIndivInvestor.useriddocument}`}
															onClick={() => setZoomed3(true)}
															onRequestClose={() => setZoomed3(false)}
														/>

														<a
															style={{
																margin: "20px 0px",
																fontSize: ".5rem",
																backgroundColor: "#252063",
																color: "#ffffff",
															}}
															className="btn btn-primary"
															download
															href={`${singleIndivInvestor.useriddocument}`}
														>
															Download Document
														</a>
													</div>
												)}
											</Col>
											<Col lg={6}>
												{singleIndivInvestor.utilityBill && (
													<div className="input-style-1">
														<label>Investors Utility Bill</label>
														<Image
															style={{ width: "150px", height: "auto" }}
															zoomed={zoomed4}
															src={`${singleIndivInvestor.utilityBill}`}
															onClick={() => setZoomed4(true)}
															onRequestClose={() => setZoomed4(false)}
														/>

														<a
															style={{
																margin: "20px 0px",
																fontSize: ".5rem",
																backgroundColor: "#252063",
																color: "#ffffff",
															}}
															className="btn btn-primary"
															download
															href={`${singleIndivInvestor?.userformdocument}`}
														>
															Download Document
														</a>
													</div>
												)}
											</Col>
										</Row>

										<Row>
											<Col lg={6}>
												{singleIndivInvestor.userdirector1passdocument && (
													<div className="input-style-1">
														<label>Director Document I</label>
														<Image
															style={{ width: "150px", height: "auto" }}
															zoomed={zoomed}
															src={`${singleIndivInvestor.userdirector1passdocument}`}
															onClick={() => setZoomed(true)}
															onRequestClose={() => setZoomed(false)}
														/>

														<a
															style={{
																margin: "20px 0px",
																fontSize: ".5rem",
																backgroundColor: "#252063",
																color: "#ffffff",
															}}
															className="btn btn-primary"
															download
															href={`${singleIndivInvestor.userdirector1passdocument}`}
														>
															Download Document
														</a>
													</div>
												)}
											</Col>
											<Col lg={6}>
												{singleIndivInvestor.userdirector2passdocument && (
													<div className="input-style-1">
														<label>Director Document II</label>
														<Image
															style={{ width: "150px", height: "auto" }}
															zoomed={zoomed2}
															src={`${singleIndivInvestor.userdirector2passdocument}`}
															onClick={() => setZoomed2(true)}
															onRequestClose={() => setZoomed2(false)}
														/>

														<a
															style={{
																margin: "20px 0px",
																fontSize: ".5rem",
																backgroundColor: "#252063",
																color: "#ffffff",
															}}
															className="btn btn-primary"
															download
															href={`${singleIndivInvestor.userdirector2passdocument}`}
														>
															Download Document
														</a>
													</div>
												)}
											</Col>
										</Row>
									</Card.Body>
								</Card>
							</Col>
						</Row>
					</div>
				) : (
					<Preloader />
				)}
			</div>
			<ModalComponent
				open={openModal}
				title={`Confirm Investor.`}
				toggle={toggleModal}
			>
				<h6
					style={{
						fontSize: "0.8rem",
					}}
				>
					Confirm an investor by validating their account number in the field
					provided below.
				</h6>
				<form>
					<div className="modal-body px-0">
						<div className="row">
							<div className="col-12 mb-2"></div>
							{showAccountForm && (
								<div className="col-12">
									<div className="input-style-1">
										<input
											type="text"
											placeholder="Account Number"
											value={accountNumbers}
											onChange={(e) => setAccountNumbers(e.target.value)}
										/>
									</div>
								</div>
							)}
							{confirmAccountNumber && (
								<>
									<div className="col-12">
										<div className="input-style-1">
											<label>Investor Name</label>
											<input
												type="text"
												placeholder="Investor Name"
												value={confirmAccountNumber?.SHAREHOLDERSNAME}
												disabled={true}
											/>
										</div>
									</div>
									<div className="col-12">
										<div className="input-style-1">
											<label>Investor CHN Number</label>
											<input
												type="text"
												placeholder="CHN Number"
												value={confirmAccountNumber?.CHN}
												disabled={true}
											/>
										</div>
									</div>
								</>
							)}
						</div>
						{!confirmAccountNumber ? (
							<div className="d-flex align-items-center justify-content-end">
								<button
									type="submit"
									className="btn primary-btn btn-hover m-1"
									onClick={(e) => submitInvestorAccount(e)}
									disabled={accountNumbers.length < 5 ? true : false}
									style={{ backgroundColor: "#252063", color: "#ffffff" }}
								>
									Validate
								</button>
							</div>
						) : (
							<div className="d-flex align-items-center justify-content-end">
								<button
									type="submit"
									className="btn primary-btn btn-hover m-1"
									onClick={modalOptions}
									disabled={checkForString() ? false : true}
									style={{ backgroundColor: "#252063", color: "#ffffff" }}
								>
									Confirm
								</button>
							</div>
						)}
					</div>
				</form>
			</ModalComponent>
			<ModalComponent open={confirmModal} toggle={toggleConfirmModal}>
				<div className={"d-flex justify-content-center mb-3"}>
					<img
						src={
							"https://static.vecteezy.com/system/resources/previews/005/129/438/original/check-icon-check-mark-icon-vector.jpg"
						}
						alt={"check"}
						width={"30px"}
						height={"30px"}
					/>
				</div>
				<h6
					style={{
						fontSize: ".8rem",
						marginBottom: "30px",
						marginTop: "10px",
					}}
				>
					Kindly verify the investors information properly before proceeding to
					confirm them.
				</h6>

				<div className="d-flex align-items-center justify-content-end">
					<button
						type="submit"
						className="btn primary-btn btn-hover m-1"
						onClick={confirmModalOptions}
						style={{ backgroundColor: "#252063", color: "#ffffff" }}
					>
						Confirm
					</button>
				</div>
			</ModalComponent>
		</section>
	);
};

export default SingleInvestor;
