import React from 'react';
import { useDispatch } from 'react-redux';
import toast from 'react-hot-toast';
import { Row, Col, Card } from '@themesberg/react-bootstrap'
import { create_brokers_account } from '../../redux/actions/authActions';
import Meta from '../../components/Meta';

const NewBroker = () => {
    const dispatch = useDispatch();

    const [ brokerName, setBrokerName ] = React.useState('')
    const [ brokerEmail, setBrokerEmail ] = React.useState('')
    const [ brokerPhone, setBrokerPhone ] = React.useState('')
    const [ brokerCode, setBrokerCode ] = React.useState('')
    const [ brokerPassword, setBrokerPassword ] = React.useState(null)

    const passwordGenerator = () => {
        const randomPassword = Math.random().toString(16).slice(8) + Math.random().toString(16).slice(8);

        setBrokerPassword(randomPassword);

        if( randomPassword ) {
            toast.success("Brokers password generated!", {
                position: "top-right"
            });
        };
    };

    const submitHandler = (e) => {
        e.preventDefault();

        const application = "devoper";
        let details = {
            name: brokerName,
            email: brokerEmail,
            phone: brokerPhone,
            password: brokerPassword,
            type: 1,
            brokerCode,
            application
        };
        dispatch(create_brokers_account( details ));
        setBrokerEmail("");
        setBrokerName("");
        setBrokerPhone("");
        setBrokerCode("");
        setBrokerPassword("");
    };

    return (
        <section className="section">
            <Meta title="Create a new broker account." />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="title mb-30">
                                <h2>Create a broker account.</h2>
                            </div>
                        </div>
                        <div
                            className='col-md-6'
                        >
                            
                        </div>
                    </div>
                </div>
                {/* Brokers Informations */}
                <Row>
                    <Col lg={12}>
                        <Card border='light' className='p-0 mb-4'>
                            <Card.Body className='pb-5'>
                                <Row>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Name</label>
                                            <input 
                                                type="text" 
                                                placeholder="Name" 
                                                value={brokerName}
                                                onChange={(e) => setBrokerName(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Email</label>
                                            <input 
                                                type="email" 
                                                placeholder="Email" 
                                                value={brokerEmail}
                                                onChange={(e) => setBrokerEmail(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Phone</label>
                                            <input 
                                                type="number" placeholder="Phone" 
                                                value={brokerPhone}
                                                onChange={(e) => setBrokerPhone(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Broker Code</label>
                                            <input 
                                                type="text" placeholder="Broker Code"
                                                value={brokerCode}
                                                onChange={(e) => setBrokerCode(e.target.value)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                {/* <Row>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Volume</label>
                                            <input type="text" placeholder="Volume" disabled />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="input-style-1">
                                            <label>Price</label>
                                            <input type="text" placeholder="Price" disabled />
                                        </div>
                                    </Col>
                                </Row> */}

                                {/* Submit Button */}
                                <Row>
                                    <div className="col-6"></div>
                                    <div className="col-12 col-md-6">
                                        <label>
                                            {
                                                !brokerPassword ?
                                                "Click button to generate brokers password." :
                                                "Password generated, submit to create account."
                                            }
                                        </label>
                                        <div
                                            className="button-group d-flex justify-content-center flex-wrap"
                                        >
                                            <button
                                                className="main-btn primary-btn btn-hover w-100 text-center mt-3"
                                                style={{ backgroundColor: "#252063", color: "#ffffff" }}
                                                onClick={ !brokerPassword ?
                                                    passwordGenerator :
                                                    submitHandler
                                                }
                                                disabled={!brokerEmail && !brokerName 
                                                    && !brokerPhone && !brokerCode
                                                }
                                            >
                                                { !brokerPassword ? "Generate Password" : "Submit" }
                                            </button>
                                        </div>
                                    </div>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </section>
    )
}

export default NewBroker