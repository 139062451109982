import { combineReducers } from "redux";

import {
  authReducer,
  brokerProfileReducer,
  getBrokersReducer,
  getSingleBrokersReducer,
  changePasswordReducer,
  brokerPasswordREcoveryReducer,
  getOtpReducer,
  getRecoveryPasswordReducer,
} from "./authReducer";

import { policyReducer } from "./policyReducer";

import {
  settlementReducer,
  getBanksReducer,
  getPouchiiTransactionReducer,
  getPouchiiUnlockedTransactionReducer,
  getPouchiiReleasedTransactionReducer,
} from "./settlementReducer";

import { messageReducer, getMessageReducer } from "./messageReducer";

import {
  getIndivInvestorsReducer,
  singleIndivInvestorsReducer,
  getCorpInvestorsReducer,
  confirmInvestorAccountReducer,
  updateInvestorStatusReducer,
  declinedInvestorsReducer,
  getAllDeclinedInvestorReducer,
  getSingleDeclinedInvestorReducer,
  getAllTradeOrderReducer,
  getSingleTradeOrderReducer,
  releaseFundsReducer,
} from "./investorsReducer";

import {
  investorTradesReducer,
  investorSellReducer,
  adminViewTradesReducer,
  investorsTradeHistoryReducer,
  singleTradeHistoryReducer,
  investorWalletReducer,
} from "./tradesReducer";

const reducer = combineReducers({
  auth: authReducer,
  otp: getOtpReducer,
  brokerPassword: changePasswordReducer,
  getBroker: getSingleBrokersReducer,
  broker: getBrokersReducer,
  profiles: brokerProfileReducer,
  policy: policyReducer,
  settlement: settlementReducer,
  message: messageReducer,
  brokerMessages: getMessageReducer,
  individual: getIndivInvestorsReducer,
  singleIndv: singleIndivInvestorsReducer,
  corporate: getCorpInvestorsReducer,
  accountNumber: confirmInvestorAccountReducer,
  trades: investorTradesReducer,
  saleOrder: investorSellReducer,
  allTrades: adminViewTradesReducer,
  status: updateInvestorStatusReducer,
  decline: declinedInvestorsReducer,
  declinedInvestors: getAllDeclinedInvestorReducer,
  singleDeclined: getSingleDeclinedInvestorReducer,
  tradeOrders: getAllTradeOrderReducer,
  brokerRecovery: brokerPasswordREcoveryReducer,
  passwordRecovery: getRecoveryPasswordReducer,
  transactionHistory: investorsTradeHistoryReducer,
  singleInvestorTransactions: singleTradeHistoryReducer,
  investorWalletBalance: investorWalletReducer,
  singleOrder: getSingleTradeOrderReducer,
  allBanks: getBanksReducer,
  release: releaseFundsReducer,
  pouchiiLock: getPouchiiTransactionReducer,
  pouchiiUnLock: getPouchiiUnlockedTransactionReducer,
  pouchiiRelease: getPouchiiReleasedTransactionReducer,
});

export default reducer;
