import React from 'react'
import  { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';
import Meta from '../../components/Meta';
import MessagesTable from '../../components/MessagesTable';
import { get_contact_messages } from '../../redux/actions/messageAction';


const Messages = () => {
  const tableRef = React.useRef();
  const dispatch = useDispatch();
  const brokerMessages = useSelector((state) => state.brokerMessages)
  const { messages } = brokerMessages

  React.useEffect(() => {
    dispatch(get_contact_messages())
  }, [dispatch]);

    return (
      <section className="section">
        <Meta title="View all brokers messages." />
        <div className="container-fluid">
            <div className="title-wrapper pt-30">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="title mb-30">
                            <h2>View Brokers Messages</h2>
                        </div>
                    </div>
                    <div className="col-md-6">
                      
                    </div>
                </div>

                {
                  
                }
                <Row>
                      <Col lg={12}>
                          <Card border='light' className='p-0 mb-4'>
                              <Card.Body className='pb-5'>
                                {
                                  messages && 
                                    messages.length > 0 && (
                                      <MessagesTable entries={messages} reference={tableRef} />
                                    )
                                }
                              </Card.Body>
                          </Card>
                      </Col>
                </Row> 
            </div>
          </div>
        </section>  
    )
  }

export default Messages