
import React from "react";

const  OutsideClick = ({ handleToggle, children}) => {
	const ref = React.useRef(null);
	
	React.useEffect(() => {
		function handleClickOutside(event) {
			if (ref.current && !ref.current.contains(event.target)) {
				handleToggle();
			}
		}

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, handleToggle, children]);

	return (
		<div className="outside-click search-mobile" ref={ref}>
			{children}
		</div>
	);
}

export default OutsideClick;