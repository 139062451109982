import { createStore, applyMiddleware } from "redux";
import thunk from 'redux-thunk'
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer } from "redux-persist";
import { createLogger } from "redux-logger";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers/rootReducer";

const logger = createLogger();

const persistConfig = {
    key: "root",
    storage,
    whitelist: ["auth"],
    blacklist: ["accountNumber"]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [ thunk ]

const REACT_APP_NODE_ENV = 'production'

const configureStore = () => {
    let store;

    if(REACT_APP_NODE_ENV === 'production') {
        store = createStore(
            persistedReducer,
            composeWithDevTools(applyMiddleware(...middleware))
        )
    } else {
        store = createStore(
            persistedReducer,
            composeWithDevTools(applyMiddleware(...middleware, logger))
        )
    }

    return store
};


export default configureStore;












