import * as types from "../types/index";

export const investorTradesReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.ALL_INVESTOR_TRADE_REQUEST:
      return { ...state, loading: true };
    case types.ALL_INVESTOR_TRADE_SUCCESS:
      return { loading: false, investorTrades: action.payload };
    case types.ALL_INVESTOR_TRADE_FAIL:
      return { loading: false, error: action.payload };
    case types.ALL_INVESTOR_TRADE_RESET:
      return {};
    default:
      return state;
  }
};

export const investorSellReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.ALL_INVESTOR_SELL_TRADE_REQUEST:
      return { ...state, loading: true };
    case types.ALL_INVESTOR_SELL_TRADE_SUCCESS:
      return { loading: false, saleTrades: action.payload };
    case types.ALL_INVESTOR_SELL_TRADE_FAIL:
      return { loading: false, error: action.payload };
    case types.ALL_INVESTOR_SELL_TRADE_RESET:
      return {};
    default:
      return state;
  }
};

export const adminViewTradesReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.ADMIN_INVESTOR_TRADE_REQUEST:
      return { ...state, loading: true };
    case types.ADMIN_INVESTOR_TRADE_SUCCESS:
      return { loading: false, adminViewTrades: action.payload };
    case types.ADMIN_INVESTOR_TRADE_FAIL:
      return { loading: false, error: action.payload };
    case types.ADMIN_INVESTOR_TRADE_RESET:
      return {};
    default:
      return state;
  }
};

export const investorsTradeHistoryReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.INVESTOR_TRANSACTION_HISTORY_REQUEST:
      return { ...state, loading: true };
    case types.INVESTOR_TRANSACTION_HISTORY_SUCCESS:
      return { loading: false, transactionHistory: action.payload };
    case types.INVESTOR_TRANSACTION_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    case types.INVESTOR_TRANSACTION_HISTORY_RESET:
      return {};
    default:
      return state;
  }
};

export const singleTradeHistoryReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.SINGLE_INVESTOR_TRANSACTION_HISTORY_REQUEST:
      return { ...state, loading: true };
    case types.SINGLE_INVESTOR_TRANSACTION_HISTORY_SUCCESS:
      return { loading: false, investorHistory: action.payload };
    case types.SINGLE_INVESTOR_TRANSACTION_HISTORY_FAIL:
      return { loading: false, error: action.payload };
    case types.SINGLE_INVESTOR_TRANSACTION_HISTORY_RESET:
      return {};
    default:
      return state;
  }
};

export const investorWalletReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.SINGLE_INVESTOR_WALLET_REQUEST:
      return { ...state, loading: true };
    case types.SINGLE_INVESTOR_WALLET_SUCCESS:
      return { loading: false, investorWallet: action.payload };
    case types.SINGLE_INVESTOR_WALLET_FAIL:
      return { loading: false, error: action.payload };
    case types.SINGLE_INVESTOR_WALLET_RESET:
      return {};
    default:
      return state;
  }
};

export const releaseFundReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.RELEASE_FUNDS_REQUEST:
      return { ...state, loading: true };
    case types.RELEASE_FUNDS_SUCCESS:
      return { loading: false, releaseBrokerFund: action.payload };
    case types.RELEASE_FUNDS_FAIL:
      return { loading: false, error: action.payload };
    case types.RELEASE_FUNDS_RESET:
      return {};
    default:
      return state;
  }
};
