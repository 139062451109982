import * as types from "../types/index";

export const getIndivInvestorsReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.GET_INDIV_INVESTOR_REQUEST:
      return { ...state, loading: true };
    case types.GET_INDIV_INVESTOR_SUCCESS:
      return { loading: false, indivInvestor: action.payload };
    case types.GET_INDIV_INVESTOR_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_INDIV_INVESTOR_RESET:
      return {};
    default:
      return state;
  }
};

export const singleIndivInvestorsReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.SINGLE_INDIV_INVESTOR_REQUEST:
      return { ...state, loading: true };
    case types.SINGLE_INDIV_INVESTOR_SUCCESS:
      return { loading: false, singleIndivInvestor: action.payload };
    case types.SINGLE_INDIV_INVESTOR_FAIL:
      return { loading: false, error: action.payload };
    case types.SINGLE_INDIV_INVESTOR_RESET:
      return {};
    default:
      return state;
  }
};

export const confirmInvestorAccountReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.CONFIRM_INVESTOR_ACCOUNT_REQUEST:
      return { ...state, loading: true };
    case types.CONFIRM_INVESTOR_ACCOUNT_SUCCESS:
      return { loading: false, confirmAccountNumber: action.payload };
    case types.CONFIRM_INVESTOR_ACCOUNT_FAIL:
      return { loading: false, error: action.payload };
    case types.CONFIRM_INVESTOR_ACCOUNT_RESET:
      return {};
    default:
      return state;
  }
};

export const getCorpInvestorsReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.GET_CORP_INVESTOR_REQUEST:
      return { ...state, loading: true };
    case types.GET_CORP_INVESTOR_SUCCESS:
      return { loading: false, corpInvestor: action.payload };
    case types.GET_CORP_INVESTOR_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_CORP_INVESTOR_RESET:
      return {};
    default:
      return state;
  }
};

export const updateInvestorStatusReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.UPDATE_INVESTOR_STATUS_REQUEST:
      return { ...state, loading: true };
    case types.UPDATE_INVESTOR_STATUS_SUCCESS:
      return { loading: false, investorStatus: action.payload };
    case types.UPDATE_INVESTOR_STATUS_FAIL:
      return { loading: false, error: action.payload };
    case types.UPDATE_INVESTOR_STATUS_RESET:
      return {};
    default:
      return state;
  }
};

export const declinedInvestorsReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.DECLINE_INVESTOR_REQUEST:
      return { ...state, loading: true };
    case types.DECLINE_INVESTOR_SUCCESS:
      return { loading: false, declinedInvestors: action.payload };
    case types.DECLINE_INVESTOR_FAIL:
      return { loading: false, error: action.payload };
    case types.DECLINE_INVESTOR_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllDeclinedInvestorReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.GET_ALL_DECLINED_INVESTOR_REQUEST:
      return { ...state, loading: true };
    case types.GET_ALL_DECLINED_INVESTOR_SUCCESS:
      return { loading: false, allDeclinedInvestors: action.payload };
    case types.GET_ALL_DECLINED_INVESTOR_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_ALL_DECLINED_INVESTOR_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleDeclinedInvestorReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.GET_SINGLE_DECLINED_INVESTOR_REQUEST:
      return { ...state, loading: true };
    case types.GET_SINGLE_DECLINED_INVESTOR_SUCCESS:
      return { loading: false, singleDeclinedInvestors: action.payload };
    case types.GET_SINGLE_DECLINED_INVESTOR_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_SINGLE_DECLINED_INVESTOR_RESET:
      return {};
    default:
      return state;
  }
};

export const getAllTradeOrderReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.GET_ALL_TRADE_ORDERS_REQUEST:
      return { ...state, loading: true };
    case types.GET_ALL_TRADE_ORDERS_SUCCESS:
      return { loading: false, brokerOrders: action.payload };
    case types.GET_ALL_TRADE_ORDERS_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_ALL_TRADE_ORDERS_RESET:
      return {};
    default:
      return state;
  }
};

export const getSingleTradeOrderReducer = (
  state = { loading: false },
  action
) => {
  switch (action.type) {
    case types.GET_SINGLE_TRADE_ORDER_REQUEST:
      return { ...state, loading: true };
    case types.GET_SINGLE_TRADE_ORDER_SUCCESS:
      return { loading: false, brokerSingleOrder: action.payload };
    case types.GET_SINGLE_TRADE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case types.GET_SINGLE_TRADE_ORDER_RESET:
      return {};
    default:
      return state;
  }
};

export const releaseFundsReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case types.RELEASE_ORDER_REQUEST:
      return { ...state, loading: true };
    case types.RELEASE_ORDER_SUCCESS:
      return { loading: false, releaseFunds: action.payload };
    case types.RELEASE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    case types.RELEASE_ORDER_RESET:
      return {};
    default:
      return state;
  }
};
