import React from 'react';
import  { useSelector, useDispatch } from 'react-redux';
import { Card } from '@themesberg/react-bootstrap';
import { get_all_brokers_account } from '../../redux/actions/authActions';
import Meta from '../../components/Meta';
import Preloader from '../../components/Preloader';
import BrokerDeclineInvestorTable from '../../components/Tables/BrokerDeclinedInvestorTable';
import { get_all_declined_investors } from '../../redux/actions/investorsActions';
import { get_all_individual_investors,
} from '../../redux/actions/investorsActions';

const BrokerDeclinedInvestors = () => {
    const dispatch = useDispatch();
    const tableRef = React.useRef();

    const auth = useSelector((state) => state.auth)
    const { detail } = auth;

    const declinedInvestors = useSelector((state) => state.declinedInvestors);
    const { allDeclinedInvestors } = declinedInvestors;

    const individual = useSelector((state) => state.individual);
    const {loading, indivInvestor } = individual;


    const getFullData = () => {
        let data = [];

        if(allDeclinedInvestors && allDeclinedInvestors.data.declinedInvestors.length > 0){
            allDeclinedInvestors.data.declinedInvestors.map((a, i) => {
                indivInvestor && indivInvestor.map((b, i) => {
                    if((a.investorId === b.id) && (a.brokerId === detail.id)) {
                        let details = {};

                        details.emailAddress = b.emailAddress;
                        details.id = b.id;
                        details.fullName = b.fullName;
                        details.phoneNumber = b.phoneNumber;

                        return data.push(details)
                    }
                })
            });
        }
        return data
    }

    const fullData = getFullData();

    React.useEffect(() => {
        dispatch(get_all_brokers_account());
        dispatch(get_all_declined_investors());
        dispatch(get_all_individual_investors());
    }, []);

    return (
        <section className="section">
            <Meta title="All Declined Investors" />
            <div className="container-fluid">
                <div className="title-wrapper pt-30">
                    <div className="row align-items-center">
                        <div className="col-md-6">
                            <div className="title mb-10">
                                <h2>Declined Investors</h2>
                            </div>
                        </div>
                        <div className="col-md-6">
                                
                        </div>
                    </div>
                </div>

                {
                    loading ? (
                        <Preloader />
                    ) : (
                    <Card border='light' className='my-5 '>
                        <Card.Body>
                            {
                                fullData &&
                                    fullData.length > 0 ? (
                                        <BrokerDeclineInvestorTable entries={fullData} reference={tableRef}/>
                                ) :  (
                                    <h6
                                        style={{ color: "#252063" }}
                                    >There are no investors declined from trading.</h6>
                                )
                            }
                        </Card.Body>
                    </Card>
                    )
                }    
            </div>
        </section>
    )
}

export default BrokerDeclinedInvestors