import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Card } from '@themesberg/react-bootstrap';

import Meta from '../../components/Meta';
import { get_all_transaction_history } from '../../redux/actions/tradesAction';
import Preloader from '../../components/Preloader';
import TransactionHistoryTable from '../../components/Tables/TransactionHistoryTable';

const TransactionHistory = () => {
    const dispatch = useDispatch();
    const tableRef = React.useRef();

    const { loading, transactionHistory } = useSelector((state) => state.transactionHistory);

    React.useEffect(() => {
        dispatch(get_all_transaction_history());
    },[ dispatch ])

  return (
    <section className="section">
    <Meta title="All transaction history" />
    <div className="container-fluid">
        <div className="title-wrapper pt-30">
            <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="title mb-30">
                        <h2>Transaction History</h2>
                    </div>
                </div>
                <div className="col-md-6">
                        
                </div>
            </div>
        </div>
    </div>

        {
            loading ? 
                (
                    <Preloader />
                ) :
                (
                    <Row>
                        <Col lg={12}>
                            <Card border='light' className='p-0 mb-4'>
                                <Card.Body className='pb-5'>
                                        {
                                            transactionHistory && 
                                                transactionHistory.length > 0 ? (
                                                    <TransactionHistoryTable entries={transactionHistory} reference={tableRef} />
                                                ) : ( <h6>There are no registered investors.</h6>)
                                        }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )
        }
    </section>
  )
}

export default TransactionHistory