import { Col, Row } from "@themesberg/react-bootstrap";

const PasswordInputField = ({
    handleValidation, 
    handlePasswordChange, 
    passwordValue, 
    passwordError
}) => {
    return (
        <div style={{ width: "100%"}}>
            <Row>
                <Col lg={12}>
                    <div className="input-style-1 password-divs">
                        <label>New Password</label>
                        <input 
                            type="password" 
                            value={passwordValue}  
                            onChange={handlePasswordChange} 
                            onKeyUp={handleValidation} 
                            name="password" 
                            placeholder="New Password" 
                        />
                        <p  style={{ fontSize: ".6rem" }} 
                            className="text-danger"
                        >{passwordError}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
export default PasswordInputField;